//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6

// import {getFromStorage, setInStorage} from './storage.js'
// import {fakeAuth} from './Authenticate_t.js'
import * as Const from './Constants'

/*********************** Documents ***************************/

export const getDocuments = (jwt) =>
  fetch(`${Const.API}/document`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const getDocumentWithId = (jwt, id) =>
  fetch(`${Const.API}/document/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const newDocument = (jwt, doc) =>
  fetch(`${Const.API}/document`, {
    method: "POST",
    body: JSON.stringify(doc),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const updateDocument = (jwt, id, doc) =>
  fetch(`${Const.API}/document/${id}`, {
    method: "PUT",
    body: JSON.stringify(doc),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const deleteDocument = (jwt, id) =>
  fetch(`${Const.API}/document/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )


  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
