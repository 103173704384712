//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import {getFromStorage, setInStorage, removeItem} from '../utils/storage.js'


  /********** Users **************/

  export function newUser(userFirstName, userLastName, userEMail, userPassword, userType, userOrganization){

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      ContactsAPI.newUser(obj.jwt,
        {
          userFirstName: userFirstName,
          userLastName: userLastName,
          userEMail: userEMail,
          userPassword: userPassword,
          userType: userType,
          userOrganization: userOrganization,

        }).then((user) => {
          console.log(`new contact added: ${user}`);

          var tempUsers = this.state.users;

          tempUsers.push(
            {
              _id: user._id,
              userFirstName: userFirstName,
              userLastName: userLastName,
              userEMail: userEMail,
              userPassword: userPassword,
              userType: userType,
              userOrganization: userOrganization,
            }
          )

          this.setState({users: tempUsers});

        });
    }
  }

  export function editUser(selectedUser, userFirstName, userLastName, userEMail, userType, userOrganization){

    var tempUserFirstName = "";
    var tempUserLastName = "";
    var tempUserEMail = "";
    var tempUserType = "";
    var tempUserOrganization = "";

    if(userFirstName === ""){
      tempUserFirstName = selectedUser.userFirstName;
    }else{
      tempUserFirstName = userFirstName;
    }

    if(userLastName === ""){
      tempUserLastName = selectedUser.userLastName;
    }else{
      tempUserLastName = userLastName;
    }

    if(userEMail === ""){
      tempUserEMail = selectedUser.userEMail;
    }else{
      tempUserEMail = userEMail;
    }

    if(userType === ""){
      tempUserType = selectedUser.userType
    }else{
      tempUserType = userType;
    }

    if(userOrganization === ""){
      tempUserOrganization = selectedUser.userOrganization
    }else{
      tempUserOrganization = userOrganization;
    }

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      ContactsAPI.updateUser(obj.jwt, selectedUser._id,
      {
        userFirstName: tempUserFirstName,
        userLastName: tempUserLastName,
        userEMail: tempUserEMail,
        userType: tempUserType,
        userOrganization: tempUserOrganization

      }).then((user) => {
        console.log(`user updated: ${user}`);

        var tempUsers = this.state.users;

        tempUsers.map((user) => {
          if(selectedUser._id === user._id){
            {

              user.userFirstName = tempUserFirstName;
              user.userLastName = tempUserLastName;
              user.userEMail = tempUserEMail;
              user.userType = tempUserType;
              user.userOrganization = tempUserOrganization;

            }
          }
        })

        this.setState({users: tempUsers});

      });
    }
  }

  export function viewUser(userId){

      var tempUsers = this.state.users;

      tempUsers.map((user) => {
        if(userId === user._id){
          {
            this.setState(
              {
                selectedUser: user
              });
          }
        }
      });
  }

  export function deleteUser(userID){

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      ContactsAPI.deleteUser(obj.jwt, userID).then((response) => {

        if(response.status === 200){
          console.log(`contact deleted`);

          var tempUsers = this.state.users;

          var filteredUsers = tempUsers.filter((user) => {
            if(user._id !== userID){
              return user;
            }
          });
        }

        this.setState({users: filteredUsers});
      });
    }
  }


  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
