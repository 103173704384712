//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import * as DocumentsAPI from '../utils/DocumentsAPI';
import * as PagesAPI from '../utils/PagesAPI';
import * as NodesAPI from '../utils/NodesAPI';
import {getFromStorage, setInStorage, removeItem} from '../utils/storage.js'

  export function newPage(pageTitle, pageParentDocID, pageParentPageID, pageSelection){
    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){
      PagesAPI.newPage(obj.jwt,
        {
          pageTitle: pageTitle,
          pageParentDocID: pageParentDocID,
          pageParentPageID: pageParentPageID,
          pageSelection: pageSelection,
          content: "",
          childPages: []
        }).then((page) => {
          console.log(`new page added: ${page}`);
          var tempPages = this.state.pages;
          var tempNodes = this.state.nodes;
          tempPages.push(
            {
              _id: page._id,
              pageTitle: pageTitle,
              pageParentDocID: pageParentDocID,
              pageParentPageID: pageParentPageID,
              pageSelection: pageSelection,
              content: "",
              childPages: []
            }
          )

          this.setState({pages: tempPages});

          //new node for the new document
          NodesAPI.newNode(obj.jwt,{
              nodeParentDocID: pageParentDocID,
              nodeParentPageID: page._id,
              nodeSelection: 'firstnode',

              nodeSourcePosition: 'right',
              nodeTaretPosition: 'left',
              nodeLabel: page.pageTitle,
              nodePositionX: '250',
              nodePositionY: '0',
            }).then((node) => {
              console.log(`new node added: ${node}`);
              tempNodes.push({
                  nodeParentDocID: node.nodeParentDocID,
                  nodeParentPageID: node.nodeParentPageID,
                  nodeSelection: node.nodeSelection,

                  id: node._id,
                  sourcePosition: node.nodeSourcePosition,
                  targetPosition: node.nodeTargetPosition,
                  type: node.nodeType,
                  data: { label: node.nodeLabel},
                  position: { x: node.nodePositionX, y: node.nodePositionY }
                }
              )
              this.setState({
                  nodes: tempNodes
              });
            });
        });
    }
  }

  //for creating a new page and adding it as a childe page
  export function newChildPage(pageTitle, pageParentDocID, pageParentPageID, pageSelection){
    const obj= getFromStorage('the_main_app')
    if (obj && obj.sessionID){
      PagesAPI.newPage(obj.jwt,
        {
          pageTitle: pageTitle,
          pageParentDocID: pageParentDocID,
          pageParentPageID: pageParentPageID,
          pageSelection: pageSelection,
          content: "",
          childPages: []
        }).then((page) => {
          console.log(`new contact added: ${page}`);
          var tempPages = this.state.pages;
          var tempSelectedPage;
          tempPages.push({
              _id: page._id,
              pageTitle: pageTitle,
              pageParentDocID: pageParentDocID,
              pageParentPageID: pageParentPageID,
              pageSelection: pageSelection,
              content: "",
              childPages: []
            }
          )

          //the new child page has been created, but the parent page does not know anything about it
          //need to modify the parent page in memory then
          //need to update the parent pag ei the database then in the UI

          tempPages.map((parentPage) => {
            if(parentPage._id !== undefined  && parentPage._id === pageParentPageID){
                parentPage.childPages.push(
                  page._id
                )
                console.log(`Parent Page ******: ${JSON.stringify(parentPage)}`);
                //now send to the update parent page to the database

                PagesAPI.updatePage(obj.jwt, parentPage._id, {
                  childPages: parentPage.childPages,
                }).then((returnedParentPage) => {
                  console.log(`returned Parent Page **************: ${JSON.stringify(returnedParentPage)}`);
                  tempSelectedPage = returnedParentPage
                  this.setState({
                      pages: tempPages,
                      selectedPage: tempSelectedPage,
                    });
                });
            }
          })
        });
    }
  }


  //for adding an existing page in a document as a child page.  This does not create a page, it just adds an existing page as a child page
  export function addChildPage(page, selectedExistingPage){
    const obj= getFromStorage('the_main_app')
    if (obj && obj.sessionID){
      
          var tempPages = this.state.pages;
          var tempSelectedPage;

          console.log(`this is the selected existing page in the addChilePage function: ${JSON.stringify(selectedExistingPage)}`);
          //need to modify the parent page in memory then
          //need to update the parent page in the database then in the UI

          tempPages.map((p) => {
            if(p._id !== undefined  && p._id === page._id){
                p.childPages.push(
                  selectedExistingPage._id
                )
                console.log(`Parent Page ******: ${JSON.stringify(p)}`);
                //now send to the update parent page to the database

                PagesAPI.updatePage(obj.jwt, p._id, {
                  childPages: p.childPages,
                }).then((returnedPage) => {
                  console.log(`returned Parent Page **************: ${JSON.stringify(returnedPage)}`);
                  tempSelectedPage = returnedPage
                  this.setState({
                      pages: tempPages,
                      //selectedPage: tempSelectedPage,
                    });
                });
            }
          })
        
    }
  }

  export function editPage(selectedPage, pageTitle, pageParentDocID, pageParentPageID, pageSelectionText, content){
    var tempPageTitle = pageTitle === "" ? selectedPage.pageTitle : pageTitle;
    var tempPageParentDocID = pageParentDocID === "" ? selectedPage.pageParentDocID : pageParentDocID;
    var tempPageParentPageID = pageParentPageID === "" ? selectedPage.pageParentPageID : pageParentPageID;
    var tempPageSelectionText = pageSelectionText === "" ? selectedPage.pageSelection : pageSelectionText;
    var tempContent = (pageSelectionText === "" && content !== "") ? content : selectedPage.content;

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){
      PagesAPI.updatePage(obj.jwt, selectedPage._id,
      {
        pageTitle: tempPageTitle,
        pageParentDocID: tempPageParentDocID,
        pageParentPageID: tempPageParentPageID,
        pageSelection: tempPageSelectionText,
        content: tempContent,
      }).then((returnedPage) => {
        console.log(`contact updated: ${JSON.stringify(returnedPage)}`);
        var tempPages = this.state.pages;
        var selectedPage = returnedPage;
        tempPages.map((page) => {
          if(selectedPage._id === page._id){
              page.pageTitle = returnedPage.pageTitle;
              page.pageParentDocID = returnedPage.pageParentDocID;
              page.pageParentPageID = returnedPage.pageParentPageID;
              page.pageSelection = returnedPage.pageSelection;
              page.content = returnedPage.content;
          }
        })
        this.setState(
          {
            pages: tempPages,
            selectedPage: returnedPage
          });
      });
    }
  }

  export function updatePage(selectedPage, pageTitle, pageParentDocID, pageParentPageID, pageSelectionText, content){
    var tempPageTitle = pageTitle === "" ? selectedPage.pageTitle : pageTitle;
    var tempPageParentDocID = pageParentDocID === "" ? selectedPage.pageParentDocID : pageParentDocID;
    var tempPageParentPageID = pageParentPageID === "" ? selectedPage.pageParentPageID : pageParentPageID;
    var tempPageSelectionText = pageSelectionText === "" ? selectedPage.pageSelection : pageSelectionText;
    var tempContent = content === "" ? selectedPage.content : content;

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){
      PagesAPI.updatePage(obj.jwt, selectedPage._id,
      {
        pageTitle: tempPageTitle,
        pageParentDocID: tempPageParentDocID,
        pageParentPageID: tempPageParentPageID,
        pageSelection: tempPageSelectionText,
        content: tempContent,
      }).then((returnedPage) => {
        console.log(`contact updated: ${JSON.stringify(returnedPage)}`);
        var tempPages = this.state.pages;
        var selectedPage = returnedPage;
        tempPages.map((page) => {
          if(selectedPage._id === page._id){
              page.pageTitle = returnedPage.pageTitle;
              page.pageParentDocID = returnedPage.pageParentDocID;
              page.pageParentPageID = returnedPage.pageParentPageID;
              page.pageSelection = returnedPage.pageSelection;
              page.content = returnedPage.content;
          }
        })
        this.setState({
            pages: tempPages,
        });
      });
    }
  }

  export function viewPage(pageID){
      window.scrollTo(0, 0)
      var tempPages = this.state.pages;
      tempPages.map((page) => {
        if(pageID === page._id){
          {
            this.setState(
              {
                selectedPage: page,
                selectedDocument: page.pageParentDocID
              });
          }
        }
      });

      console.log(`Selected page: ${JSON.stringify(this.state.selectedPage)}`);
  }

  /**************************************************   Delete Page Function   ****************************************************/

  export function deletePage(pageID){
    const obj= getFromStorage('the_main_app')
    if (obj && obj.sessionID){

      console.log(`The pageID provided to the deletePage function is: ${pageID}`);
      
      var tempPages = this.state.pages;

      var deletedPage = tempPages.find((page) => page._id === pageID);

      console.log(`The object for the page to delete has been found: ${deletedPage._id}`)
      console.log(`The page to delete parent page ID: ${deletedPage.pageParentPageID}`)
      console.log(`The page to delete childePages: ${JSON.stringify(deletedPage.childPages)}`)


      // We need to check to see if this page is the first page in the document because we do not want to delete the first page in the document
      // if we want to delete the first page in the document the user needs to delte the document
      if(deletedPage.pageParentPageID !== "firstpage"){
        
        //this is the not the first page in the document
        console.log(`This is NOT the first page in the document.  You can delete this page.  The pageID is: ${deletedPage._id}`);

        //send request to API to delete the page from the server database
        PagesAPI.deletePage(obj.jwt, pageID).then((response) => {
          
          //check to see if the page was successfully deleted from the server
          //if the page is deleted successfully on the server, then we can delete the page from the state
          if(response.status === 200){
            
            console.log(`Page successfully deleted from server, response: ${response.status}`);
           
            //We need to update the local state of the pages to reflect the deletion of the page from the server
            //set filteredPages equal to the current state so we can manipulate update the pages of the state
            var filteredPages = this.state.pages;

            //remove the deleted page from this filtered pages by looking for the deleted page and returning all other pages except the deleted page
            filteredPages = filteredPages.filter((page) => {
              if(page._id !== pageID){
                return page;
              }
            });
            
            //now that the deleted page has been removed from the filtered pages, we can update the state of the pages to reflect the deletion of the page
            //update the state of the pages, delete page removed
            this.setState({pages: filteredPages});


            //**************************************************    Deleted Page's Parent Page Work   ***********************************************/


            //map through the list of pages so we can find the parent page of the deleted page
            var filteredPages = filteredPages.map((page) => {

              
              //now we need to work on the parent page of the deleted page and the child pages of the deleted page
              //the deleted page nees to be removed from teh parent page child pages and any child pages of the deleted page need to be added (moved up) to the deleteed pages parent page
              
              //identify the parent page of the deleted page
              if(page._id === deletedPage.pageParentPageID){

                console.log(`Found the deleted pages' parent page: ${page._id}`);
                console.log(`The deleted pages' parent page child pages before removing deleted page: ${JSON.stringify(page.childPages)}`);

                //remove the deleted page from the parent
                
                
                const exists1 = page.childPages.some(parentChild => parentChild === deletedPage._id);
                
                if (exists1) {
                  page.childPages = page.childPages.filter(parentChild => parentChild !== deletedPage._id);
                }

                console.log(`The deleted pages' parent page child pages after removing deleted page: ${JSON.stringify(page.childPages)}`);

                
                deletedPage.childPages.forEach(foundChild => {
                  
                  console.log(`foundChild: ${foundChild}`);
                  //check to see of the child page already exists in the parent page
                  //if it doesnt add the child page to the parent page
                  const exists2 = page.childPages.some(parentChild => parentChild === foundChild);
                  
                  if (!exists2) {
                    page.childPages.push(foundChild);
                  }

                });
              }
  
              console.log(`right before update page: ${page._id}`);
  
              PagesAPI.updatePage(obj.jwt, page._id, {
                childPages: page.childPages,
              }).then((returnedPage) => {
                console.log(`returned page *************: ${returnedPage}`);
                this.setState({pages: filteredPages});
              });
  
              return page;

            });

            this.setState({pages: filteredPages});

            filteredPages = filteredPages.map((page) => {

            //if the pages parent page id is equal the deleted pages id, we found the page to update
              if(page.pageParentPageID === deletedPage._id){
                //update the pages parent page to the new parent page
                page.pageParentPageID = deletedPage.pageParentPageID;
              }

              PagesAPI.updatePage(obj.jwt, page._id, {
                
                pageParentPageID: page.pageParentPageID,

              }).then((returnedPage) => {
                console.log(`returned page *************: ${returnedPage}`);
                this.setState({pages: filteredPages});
              });

              return page;

            // update the pages parent page to the new parent page

            });

          }
        });


      }else{
        //this is the first page in the document
        console.log(`this is the first page in the document.  You cannot delete the first page of a document.  You must delete the document.  The documentID is: ${deletedPage.pageParentDocID}`);
      }

    }
  } /* End delete page function */

  /**************************************************   Remove Child Page Function   ****************************************************/

  export function removeChildPage(childPageID, selectedPageID){
    const obj= getFromStorage('the_main_app')
    if (obj && obj.sessionID){

      
      console.log(`The pageID provided to the removeChildPage function is: ${childPageID}`);

      console.log(`The selected page inside removeChildPage function is: ${selectedPageID}`)
      
     
     
      var childTempPages = this.state.pages;

      var tempPages = this.state.pages;

      
      
      var childPage = childTempPages.find((page) => page._id === childPageID);

      var selectedPage = tempPages.find((page) => page._id === selectedPageID);



      console.log(`The object for the childPage ID to remove has been found: ${childPage._id}`)

      console.log(`The object for the selectdPageID to remove has been found: ${selectedPage._id}`)

      tempPages.map((page) => {

        if(page._id === selectedPageID){
          
          console.log(`The selected page before removing the child page: ${JSON.stringify(page)}`);
          console.log(`The selected page child pages before removing the child page: ${JSON.stringify(page.childPages)}`);
          
          const exists = page.childPages.some(parentChild => parentChild === childPageID);
         
          if (exists) {
            page.childPages = page.childPages.filter(parentChild => parentChild !== childPageID);
          }
          
          console.log(`The selected page child pages after removing the child page: ${JSON.stringify(page.childPages)}`);
          console.log(`The selected page after removing the child page: ${JSON.stringify(page)}`);


          PagesAPI.updatePage(obj.jwt, page._id, {
            childPages: page.childPages,
          }).then((returnedPage) => {
            console.log(`returned page *************: ${returnedPage._id}`);
            //this.setState({pages: filteredPages});
          });
        }

      });

      //console.log(`right before update page: ${tempPages}`);

      this.setState({pages: tempPages});


      /*
      //map through the list of pages so we can find the parent page of the deleted page
      var filteredPages = filteredPages.map((page) => {

      //now we need to work on the parent page of the deleted page and the child pages of the deleted page
      //the deleted page nees to be removed from teh parent page child pages and any child pages of the deleted page need to be added (moved up) to the deleteed pages parent page
      
      //identify the parent page of the deleted page
      if(page._id === removedPage.pageParentPageID){

        console.log(`Found the deleted pages' parent page: ${page._id}`);
        console.log(`The deleted pages' parent page child pages before removing deleted page: ${JSON.stringify(page.childPages)}`);

        //remove the deleted page from the parent
        
        
        const exists1 = page.childPages.some(parentChild => parentChild === removedPage._id);
        
        if (exists1) {
          page.childPages = page.childPages.filter(parentChild => parentChild !== removedPage._id);
        }

        console.log(`The deleted pages' parent page child pages after removing deleted page: ${JSON.stringify(page.childPages)}`);

        
        removedPage.childPages.forEach(foundChild => {
          
          console.log(`foundChild: ${foundChild}`);
          //check to see of the child page already exists in the parent page
          //if it doesnt add the child page to the parent page
          const exists2 = page.childPages.some(parentChild => parentChild === foundChild);
          
          if (!exists2) {
            page.childPages.push(foundChild);
          }

        }); 
      }

      console.log(`right before update page: ${page._id}`);

      PagesAPI.updatePage(obj.jwt, page._id, {
        childPages: page.childPages,
      }).then((returnedPage) => {
        console.log(`returned page *************: ${returnedPage}`);
        this.setState({pages: filteredPages});
      });

      return page;

    });*/

    }
  }

  
  
  
  /**************************************************   Delete Many Pages Function   ****************************************************/

  export function deleteManyPages(docID){
    const obj= getFromStorage('the_main_app')
    if (obj && obj.sessionID){
      PagesAPI.deleteManyPages(obj.jwt, docID).then((response) => {
        if(response.status === 200){
          console.log(`pages deleted`);
          var tempPages = this.state.pages;
          var filteredPages = tempPages.filter((page) => {
            if(page.pageParentDocID !== docID){
              return page;
            }
          });
        }

        this.setState({pages: filteredPages});
      });
    }
  }

  export function changeChildPageOrder(page, childPage, oldChildPageOrder, newChildPageOrder){
    console.log("Current Order: " + oldChildPageOrder)
    console.log("Old Order: " + newChildPageOrder)

    var tempPages = this.state.pages;
    tempPages.map((p) => {
      if(p._id === page._id){
          console.log("Page Content ******" + p.childPages)
          p.childPages.splice(oldChildPageOrder, 1)
          p.childPages.splice(newChildPageOrder - 1, 0, childPage._id)
          console.log(`Parent Page ******: ${JSON.stringify(p)}`);
          //now send to the update parent page to the database
          const obj= getFromStorage('the_main_app')
          if (obj && obj.sessionID){
            PagesAPI.updatePage(obj.jwt, p._id, {
              childPages: p.childPages,
            }).then((returnedPage) => {
              console.log(`returned Parent Page **************: ${JSON.stringify(returnedPage)}`);
              this.setState({
                  pages: tempPages,
              });
            });
          }
      }
    })

  }
  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
