//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, } from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class EditUserAdmin extends Component{
  state = {
    query: '',
    eventMarketName: '',
    eventFirstName: '',
    eventLastName: '',
    eventEMail: '',
    eventUserType: '',
    eventUserOrganization: '',
  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){
      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  handleFirstNameChange = (event) => {

    this.setState({eventFirstName: event.target.value})
    console.log('onType', this.state.eventFirstName)
  }

  handleLastNameChange = (event) => {

    this.setState({eventLastName: event.target.value})
    console.log('onType', this.state.eventLastName)
  }

  handleEMailChange = (event) => {

    this.setState({eventEMail: event.target.value})
    console.log('onType', this.state.eventEMail)
  }

  handlePasswordChange = (event) => {

    this.setState({eventPassword: event.target.value})
    console.log('onType', this.state.eventPassword)
  }

  handleUserTypeChange = (event) => {

    this.setState({eventUserType: event.target.value})
    console.log('onType', this.state.eventUserType)
  }

  handleUserOrganizationChange = (event) => {

    this.setState({eventUserOrganization: event.target.value})
    console.log('onType', this.state.eventUserOrganization)
  }

/*
  onSubmitHandle(event) {
  event.preventDefault();
  this.setState({

  });
  console.log('tsting')
  event.target.item.value = '';
}*/


  render(){
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredUsers = this.props.users.filter(
      (user) => {

        return user.userFirstName.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1
      }
    )


    return(
      <div className="viewPage">

        <Container>
          <br/>
          <h1>User: {this.props.selectedUser.userFirstName} {this.props.selectedUser.userLastName}</h1>
          <p>User ID: {this.props.selectedUser._id}</p>
          <p>Organization: {this.props.selectedUser.userOrganization}</p>
          <p>Team: {this.props.selectedUser.userTeam}</p>
          <p>User Type: {this.props.selectedUser.userType}</p>
          <br/>
        <h4>Edit User</h4>
        <div>
        <form >
          <FormGroup  controlId="formControlsTextarea">

              <FormLabel>First Name</FormLabel>
              <FormControl onChange={this.handleFirstNameChange} defaultValue={this.props.selectedUser.userFirstName} placeholder="Enter First Name" style={{ height: 30 }}/>
              <FormLabel>Last Name</FormLabel>
              <FormControl onChange={this.handleLastNameChange} defaultValue={this.props.selectedUser.userLastName} placeholder="Enter Last Name" style={{ height: 30 }}/>
              <FormLabel>E-Mail</FormLabel>
              <FormControl onChange={this.handleEMailChange} defaultValue={this.props.selectedUser.userEMail} placeholder="Enter E-Mail" style={{ height: 30 }}/>
              <FormLabel>Password</FormLabel>
              <FormControl onChange={this.handlePasswordChange} placeholder="Enter Password" type="password" style={{ height: 30 }}/>
              <FormLabel>User Type</FormLabel>
              <FormControl onChange={this.handleUserTypeChange} defaultValue={this.props.selectedUser.userType} placeholder="Enter User Type" style={{ height: 30 }}/>
              <FormLabel>User Organization</FormLabel>
              <FormControl onChange={this.handleUserOrganizationChange} defaultValue={this.props.selectedUser.userOrganization} placeholder="Enter User Organization" style={{ height: 30 }}/>
          </FormGroup>
          <Button onClick={() => this.props.editUser(this.props.selectedUser, this.state.eventFirstName, this.state.eventLastName, this.state.eventEMail, this.state.eventUserType, this.state.eventUserOrganization)} bsstyle="success">Update User</Button>
        </form>
        <br/>
        <br/>
        </div>
        </Container>
        <br/>
        <br/>
      </div>
    )
  }
}
export default EditUserAdmin;
