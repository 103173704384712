//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6

// import {getFromStorage, setInStorage} from './storage.js'
// import {fakeAuth} from './Authenticate_t.js'
import * as Const from './Constants'

/*********************** Pages ***************************/

export const getPages = (jwt) =>
  fetch(`${Const.API}/page`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const getPageWithId = (jwt, id) =>
  fetch(`${Const.API}/page/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const newPage = (jwt, page) =>
  fetch(`${Const.API}/page`, {
    method: "POST",
    body: JSON.stringify(page),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const updatePage = (jwt, id, page) =>
  fetch(`${Const.API}/page/${id}`, {
    method: "PUT",
    body: JSON.stringify(page),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const deletePage = (jwt, id) =>
  fetch(`${Const.API}/page/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )

export const deleteManyPages = (jwt, id) =>
  fetch(`${Const.API}/pages/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )

  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
