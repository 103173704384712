//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class EditPageAdmin extends Component{
  state = {
    query: '',
    eventFirstName: '',
    eventDocumentTitle: '',
    eventDocumentAlarmCodes: '',
    eventFirstPageID: '',
    show: false,
    selectedDocument: '',
    selectedPage: '',

    eventContentParentDocID: '',
    eventContentParentPageID: '',
    eventContentType: '',
    eventContentText: '',
    eventContentImagePublicID: '',
    eventContentImageURL: '',
    eventContentOrder: '',


    eventChildPageTitle: '',
    eventChildPageSelectionText: '',

    eventPageTitle: '',
    eventPageParentDocID: '',
    eventPageParentPageID: '',
    eventPageSelectionText: '',

  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){
      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  handleShow = (page) => {
    this.setState(
      {
        show: true,
        selectedPage: page,
      });
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleCloseAndDelete = (pageID) => {

    this.setState({show: false});
    this.props.deletePage(pageID)
  }

  handleContentParentDocID = (event) => {

    this.setState({eventContentParentDocID: event.target.value})
    console.log('onType', this.state.eventContentParentDocID)
  }

  handleContentParentPageID = (event) => {

    this.setState({eventContentParentPageID: event.target.value})
    console.log('onType', this.state.eventContentParentPageID)
  }

  handleContentType = (event) => {

    this.setState({eventContentType: event.target.value})
    console.log('onType', this.state.eventContentType)
  }

  handleContentText = (event) => {

    this.setState({eventContentText: event.target.value})
    console.log('onType', this.state.eventContentText)
  }

  handleContentImagePublicID = (event) => {

    this.setState({eventContentImagePublicID: event.target.value})
    console.log('onType', this.state.eventContentImagePublicID)
  }

  handleContentImageURL = (event) => {

    this.setState({eventContentImageURL: event.target.value})
    console.log('onType', this.state.eventContentImageURL)
  }


  handleDocumentTitleChange = (event) => {

    this.setState({eventDocumentTitle: event.target.value})
    console.log('onType', this.state.eventDocumentTitle)
  }

  handleDocumentAlarmCodes = (event) => {

    this.setState({eventDocumentAlarmCodes: event.target.value})
    console.log('onType', this.state.eventDocumentAlarmCodes)
  }

  handleFirstPageID = (event) => {

    this.setState({eventFirstPageID: event.target.value})
    console.log('onType', this.state.eventFirstPageID)
  }

  handleChildPageTitleChange = (event) => {

    this.setState({eventChildPageTitle: event.target.value})
    console.log('onType', this.state.eventChildPageTitle)
  }

  handleChildPageSelectionText = (event) => {

    this.setState({eventChildPageSelectionText: event.target.value})
    console.log('onType', this.state.eventChildPageSelectionText)
  }

  handlePageTitleChange = (event) => {

    this.setState({eventPageTitle: event.target.value})
    console.log('onType', this.state.eventPageTitle)
  }

  handlePageParentDocIDChange = (event) => {

    this.setState({eventPageParentDocID: event.target.value})
    console.log('onType', this.state.eventPageParentDocID)
  }

  handlePageParentPageIDChange = (event) => {

    this.setState({eventPageParentPageID: event.target.value})
    console.log('onType', this.state.eventPageParentPageID)
  }

  handlePageSelectionTextChange = (event) => {

    this.setState({eventPageSelectionText: event.target.value})
    console.log('onType', this.state.eventPageSelectionText)
  }


  render(){
    console.log(`marketlistedit props ${this.props}`)
    console.log('Props', this.props)


    if(this.props.isLoading && this.props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else{

      if(this.props.isLoading  || this.props.sessionIsLoading){
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>CW-Proj is Loading</h1>
            </Jumbotron>
          </div>
        )
      }
    }

    /*if(!this.props.isAuthenticated){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>you are not logged in go back</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">login</Button>
          </LinkContainer>
        </div>
      )
    }*/
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredPages = this.props.pages.filter(
      (page) => {

        if(page !== undefined){
          return page.pageTitle.toLowerCase().indexOf(this.state.query) !== -1
        }

      }
    )

    return(

      <div className="viewPage">

        <Container>

        {this.props.selectedPage.pageParentPageID === undefined || this.props.selectedPage.pageParentPageID === "firstpage" ?

          <div className="text-left">
            <LinkContainer to="/viewdocumentlist">
              <Button bsstyle="success">Back to Document List</Button>
            </LinkContainer>
            <br/>
            <br/>
          </div>


            :

          <div className="text-left">
              <Button onClick={() => this.props.viewPage(this.props.selectedPage.pageParentPageID)} bsstyle="success">Previous Page</Button>
              <br/>
              <br/>
          </div>

        }

        <br/>
        <h1>Page: {this.props.selectedPage.pageTitle}</h1>
        <p>Selected Page ID: {this.props.selectedPage._id}</p>
        <p>Parent Doc ID: {this.props.selectedPage.pageParentDocID}</p>
        <p>Parent Page ID: {this.props.selectedPage.pageParentPageID}</p>
        <p>Page Selection: {this.props.selectedPage.pageSelection}</p>
        <br/>

        <form >
          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Page Title</FormLabel>
              <FormControl onChange={this.handlePageTitleChange} defaultValue={this.props.selectedPage.pageTitle} placeholder="Enter a Page Title" style={{ height: 30 }}/><br/>
              <FormLabel>Page Doc ID</FormLabel>
              <FormControl onChange={this.handlePageParentDocIDChange}  defaultValue={this.props.selectedPage.pageParentDocID} placeholder="Enter a Parent Document ID" style={{ height: 30 }}/><br/>
              <FormLabel>Page Parent ID</FormLabel>
              <FormControl onChange={this.handlePageParentPageIDChange}  defaultValue={this.props.selectedPage.pageParentPageID} placeholder="Enter a Parent Page ID" style={{ height: 30 }}/><br/>
              <FormLabel>Page Selection</FormLabel>
              <FormControl onChange={this.handlePageSelectionTextChange}  defaultValue={this.props.selectedPage.pageSelection} placeholder="Enter page selection text" style={{ height: 30 }}/><br/>
              <Button onClick={() => this.props.editPage(this.props.selectedPage, this.state.eventPageTitle, this.state.eventPageParentDocID, this.state.eventPageParentPageID, this.state.eventPageSelectionText)} bsstyle="success">Update Page</Button><br/><br/>
          </FormGroup>

        </form>

        <br/>
        <h1>{this.props.selectedPage.pageTitle} Content</h1>

        <br/>
        <h4>Add Content to Page: {this.props.selectedPage.pageTitle}</h4>
        <br/>

        <form >

          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Content Type</FormLabel>
              <FormControl onChange={this.handleContentType} placeholder="Enter Content Type" style={{ height: 30 }}/>
              <FormLabel>Content Text</FormLabel>
              <FormControl onChange={this.handleContentText} placeholder="Enter Content Text" style={{ height: 30 }}/>
              <FormLabel>Content Image URL</FormLabel>
              <FormControl onChange={this.handleContentImageURL} placeholder="Enter Content Image URL" style={{ height: 30 }}/>
              <FormLabel>Content Image Public ID</FormLabel>
              <FormControl onChange={this.handleContentImagePublicID} placeholder="Enter Content Image Public ID" style={{ height: 30 }}/>
              <FormLabel>Content Order</FormLabel>
              <FormControl onChange={this.handleContentOrderChange} placeholder="Enter the order of the Content" style={{ height: 30 }}/><br/>
              <br/>
              <Button onClick={() => this.props.newPageContent(this.props.selectedPage.pageParentDocID, this.props.selectedPage._id, this.state.eventContentType, this.state.eventContentText, this.state.eventContentImageURL, this.state.eventContentImagePublicID)} bsstyle="success">New Page Content</Button><br/><br/>
          </FormGroup>

        </form>

        <br/>
        <Row className="Testing2"  >
          <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="formControlsTextarea2">
                  <FormLabel>Search</FormLabel>

                  <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Page Title" />

              </FormGroup>
            </Col>
          </Row>
          <Row className="Testing2"  >
            <Col xs={1} sm={1} md={1} lg={1}>
              <h6>#</h6>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <h6>Content ID</h6>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <h6>Doc ID</h6>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <h6>Page ID</h6>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <h6>Content Type</h6>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <h6>Action</h6>
            </Col>
          </Row>

          {this.props.selectedPage.content !== undefined && this.props.selectedPage.content.length !== 0 ? this.props.selectedPage.content.map((value, ia) => (

            this.props.content.filter(content => content._id === value).map((content, ib) => (

              <Row key={ib} className="Testing"  >
                <Col xs={1} sm={1} md={1} lg={1}>
                  {ia + 1}
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <p>{content._id !== undefined ? content._id.substring(content._id.length - 5, content._id.length) : "No ID"}</p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <p>{content.contentParentDocID !== undefined ? content.contentParentDocID.substring(content.contentParentDocID.length - 5, content.contentParentDocID.length) : "No ID"}</p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <p>{content.contentParentPageID !== undefined ? content.contentParentPageID.substring(content.contentParentPageID.length - 5, content.contentParentPageID.length) : "No ID"}</p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  {content.contentType}
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <LinkContainer to="/editcontentadmin">
                    <Button onClick={() => this.props.viewContent(content._id)} bsstyle="success">Edit</Button>
                  </LinkContainer>
                </Col>
              </Row>
            )

        ))) :
          <Row className="Testing">
            <Col xs={12} sm={12} md={12} lg={12}>

              <h5>Currently no Content</h5>

            </Col>
          </Row>

        }

        <br/>
        <br/>
        <br/>
        <h1>{this.props.selectedPage.pageTitle} Child Pages</h1>
        <br/>
        <h4>Add Child Page to Page: {this.props.selectedPage.pageTitle}</h4>
        <br/>

      <form >

        <FormGroup  controlId="formControlsTextarea">
            <FormLabel>Child Page Title</FormLabel>
            <FormControl onChange={this.handleChildPageTitleChange} placeholder="Enter a Child Page Title" style={{ height: 30 }}/><br/>
            <FormLabel>Child Page Selection Text</FormLabel>
            <FormControl onChange={this.handleChildPageSelectionText} placeholder="Enter Child Page selection text" style={{ height: 30 }}/><br/>
            <Button onClick={() => this.props.newChildPage(this.state.eventChildPageTitle, this.props.selectedPage.pageParentDocID, this.props.selectedPage._id, this.state.eventChildPageSelectionText)} bsstyle="success">New Child Page</Button><br/><br/>
        </FormGroup>

      </form>

      <br/>
      <Row className="Testing2"  >
        <Col xs={12} sm={12} md={12} lg={12}>
            <FormGroup controlId="formControlsTextarea2">
                <FormLabel>Search</FormLabel>

                <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Page Title" />

            </FormGroup>
          </Col>
        </Row>
        <Row className="Testing2"  >
          <Col xs={1} sm={1} md={1} lg={1}>
            <h6>#</h6>
          </Col>
          <Col xs={1} sm={1} md={1} lg={1}>
            <h6>Child Page ID</h6>
          </Col>
          <Col xs={7} sm={7} md={7} lg={7}>
            <h6>Page Selection Text</h6>
          </Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            <h6>Action</h6>
          </Col>
        </Row>
        {this.props.selectedPage.childPages !== undefined && this.props.selectedPage.childPages.length !== 0 ? this.props.selectedPage.childPages.map((value, ia) => (

          this.props.pages.filter(page => page._id !== 0 && page._id === value).map((page, ib) => (

            <Row key={ib} className="Testing"  >
              <Col xs={1} sm={1} md={1} lg={1}>
                {ia + 1}
              </Col>

              <Col xs={1} sm={1} md={1} lg={1}>
                <p>{page._id !== undefined ? page._id.substring(page._id.length - 5, page._id.length) : "No ID"}</p>
              </Col>

              <Col xs={7} sm={7} md={7} lg={7}>
                {page.pageSelection}
              </Col>
              <Col xs={3} sm={3} md={3} lg={3}>

                  <Button onClick={() => this.props.viewPage(page._id)} bsstyle="success">Edit</Button>&nbsp;&nbsp;
                  <Button variant="danger" onClick={() => this.handleShow(page)}>
                    Delete
                  </Button>
              </Col>

              <Modal show={this.state.show} onHide={this.handleClose}>
               <Modal.Header closeButton>
                 <Modal.Title>Delete Page</Modal.Title>
               </Modal.Header>
               <Modal.Body>Warning!!! Are you sure you want to delete the page "{this.state.selectedPage.pageTitle}"?</Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={this.handleClose}>
                   Close
                 </Button>
                 <Button variant="danger" onClick={() => this.handleCloseAndDelete(this.state.selectedPage._id)}>
                   Delete
                 </Button>
               </Modal.Footer>
             </Modal>
            </Row>

          )

      ))) :
        <Row className="Testing"  >
          <Col xs={12} sm={12} md={12} lg={12}>

            <h5>Currently no Child Pages</h5>

          </Col>
        </Row>

      }
        </Container>

      </div>


    )
  }
}
export default EditPageAdmin;
