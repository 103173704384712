//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//import React, logo, App, ContactList, Button, ButtonToolbar, BrowserRouter, ContactsAPI

import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import 'quill/dist/quill.snow.css';
import {fakeAuth} from './utils/Authenticate_t.js'
import {getFromStorage, setInStorage, removeItem} from './utils/storage.js'
import Login from './components/Login';
import Navigation from './components/Navigation.js';
import UserList from './components/UserList';
import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom';

import DocumentListAdmin from './components/DocumentListAdmin';
import EditDocumentList from './components/EditDocumentList';
import ViewDocumentList from './components/ViewDocumentList';
import PageList from './components/PageList';
import EditDocumentAdmin from './components/EditDocumentAdmin';
import EditPageAdmin from './components/EditPageAdmin';
import EditUserAdmin from './components/EditUserAdmin';
import ViewDocument from './components/ViewDocument';
import EditDocument from './components/EditDocument';
import EditDocumentNew from './components/EditDocumentNew';

import ReactFlowWrapper from './components/reactFlow/src/App';

import * as ContactsAPI from './utils/ContactsAPI';
import * as DocumentsAPI from './utils/DocumentsAPI';
import * as PagesAPI from './utils/PagesAPI';
import * as NodesAPI from './utils/NodesAPI';
import * as DocumentPDFAPI from './utils/DocumentPDFAPI';
import * as CommentsAPI from './utils/CommentsAPI';
import * as Users from './functions/Users';
import * as LogInLogOut from './functions/LogInLogOut';

import * as Documents from './functions/Documents';
import * as Pages from './functions/Pages';
import * as Comments from './functions/Comments';

import * as DocumentPDF from './functions/DocumentPDF';

//import { Dropbox } from 'dropbox';


class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      users: [],

      documents: [],
      pages: [],
      nodes: [],
      edges: [],
      documentPDF: {},
      comments: [],

      selectedDocument: {},
      selectedPage: {},
      selectedUser: {},
      selectedMarket: {},
      selectedDeliverable: {},
      selectedContact: {},

      contacts: [],

      isAuthenticated: false,
      isLoading: false,
      sessionIsLoading: true,
      incorrectPasswordUser: false,
      userType: "",
      userOrganization: "",
      userTeam: "",
      userFirstName: "",
      userLastName: "",

      pathName: "/viewdocumentlist",
    }

    this.newUser = Users.newUser.bind(this);
    this.editUser = Users.editUser.bind(this);
    this.viewUser = Users.viewUser.bind(this);
    this.deleteUser = Users.deleteUser.bind(this);

    this.newDocument = Documents.newDocument.bind(this);
    this.editDocument = Documents.editDocument.bind(this);
    this.viewDocument = Documents.viewDocument.bind(this);
    this.deleteDocument = Documents.deleteDocument.bind(this);

    this.viewPage = Pages.viewPage.bind(this);
    this.editPage = Pages.editPage.bind(this);
    this.updatePage = Pages.updatePage.bind(this);
    this.newPage = Pages.newPage.bind(this);
    this.newChildPage = Pages.newChildPage.bind(this);
    this.addChildPage = Pages.addChildPage.bind(this);
    this.deletePage = Pages.deletePage.bind(this);
    this.changeChildPageOrder = Pages.changeChildPageOrder.bind(this);

    this.removeChildPage = Pages.removeChildPage.bind(this);

    this.newComment = Comments.newComment.bind(this);
    this.newChildComment = Comments.newChildComment.bind(this);

    this.signInUser = LogInLogOut.signInUser.bind(this);
    this.logOut = LogInLogOut.logOut.bind(this);
    //this.verifyUser = this.verifyUser.bind(this);

    this.authenticate = LogInLogOut.authenticate.bind(this);

    this.getPDFWithId = DocumentPDF.getPDFWithId.bind(this);
  }

  componentDidMount(){
    //dropbox testing **************
    const accessToken = 'dmqRENdnI_UAAAAAAAAHLdjGRJ-lUtgKCQ2N1jQq3Pf9NSZ9VeZMrKU8cIfeVrxc';
    /*const dbx = new Dropbox({
      accessToken,
      fetch
    });

    dbx.filesListFolder({
      path: ''
    }).then(response => console.log(`testing dropbox ${JSON.stringify(response)}`))*/

    //end dropbox testing ******

    const obj= getFromStorage('the_main_app')

    console.log(`storage object ${JSON.stringify(obj)}`);
    //console.log(`storage object sessionId ${obj.sessionID}`);

    if (obj && obj.sessionID){

      //verify the token
      console.log(`There is an object in storage ${obj.sessionID} and ${obj.token}`);

      ContactsAPI.verifyUser(obj.sessionID).then((res) => {
        console.log("Response from verify API");
        console.log(`Response form verify API, response.success ${JSON.stringify(res)}`);

        if(res.success){
          console.log(`is Authenticated: 1 ${this.state.isAuthenticated}`)

          this.authenticate()
          this.setState({sessionIsLoading: false})
          this.setState({userType: obj.userType})
          this.setState({userOrganization: obj.userOrganization})
          this.setState({userTeam: obj.userTeam})
          this.setState({userFirstName: obj.userFirstName})
          this.setState({userLastName: obj.userLastName})

          console.log('hellow form inside verify API')
          console.log(`is Authenticated: 2 ${this.state.isAuthenticated}`)

          ContactsAPI.getUsers(obj.jwt).then((contacts) => {
            console.log(`contacts downloaded: ${JSON.stringify(contacts)}`);
              this.setState({users: contacts});
          });

          DocumentsAPI.getDocuments(obj.jwt).then((docs) => {
            console.log(`Documents downloaded: ${JSON.stringify(docs)}`);
              this.setState({documents: docs});
          });

          PagesAPI.getPages(obj.jwt).then((pages) => {
            console.log(`Pages downloaded: ${JSON.stringify(pages)}`);
              this.setState({pages: pages});
          });

          NodesAPI.getNodes(obj.jwt).then((nodes) => {
              console.log(`Nodes downloaded: ${JSON.stringify(nodes)}`);
              var tempNodes = nodes;
              console.log(`TempNodes Test: ${JSON.stringify(tempNodes)}`)
              //labels in database do not match so need to change them in this one
              const updatedNodes = tempNodes.map((item) => {
                const newItem = { ...item };

                delete newItem._id;
                delete newItem.nodeSourcePosition;
                delete newItem.nodeType;
                delete newItem.nodeLabel;
                delete newItem.nodePositionX;
                delete newItem.nodePositionY;

                newItem.id = item._id;
                newItem.sourcePosition = item.nodeSourcePosition;
                newItem.type = item.nodeType;
                newItem.data = { label: item.nodeLabel };
                newItem.position = { x: item.nodePositionX, y: item.nodePositionY };

                return newItem;
              });
              console.log(`TempNodes Test2: ${JSON.stringify(updatedNodes)}`)
              this.setState({nodes: updatedNodes});
          });

          DocumentPDFAPI.getPDF(obj.jwt).then((pdf) => {
            console.log(`PDF downloaded: ${JSON.stringify(pdf)}`);
              this.setState({documentPDF: pdf});
          });

          CommentsAPI.getComments(obj.jwt).then((comments) => {
            console.log(`Comments downloaded: ${JSON.stringify(comments)}`);
              comments.reverse();
              this.setState({comments: comments});
          });
          return "Success ya know!!!!!"
        }
      })
    }else{
      this.setState({sessionIsLoading: false})
    }

    setInterval( () => {
      const obj= getFromStorage('the_main_app')
      console.log(`storage object ${JSON.stringify(obj)}`);
      //console.log(`storage object sessionId ${obj.sessionID}`);
        if (obj && obj.sessionID){
          CommentsAPI.getComments(obj.jwt).then((comments) => {
            console.log(`Comments downloaded: ${JSON.stringify(comments)}`);
              comments.reverse();
              this.setState({comments: comments});
            });
        }
    },60000)
  }


  render(){
    return(
      <Router>
        <div>
            <Navigation userType={this.state.userType} isAuthenticated={this.state.isAuthenticated} logOut={this.logOut}/>
            <Route exact strict path="/" render={() => (
                <Redirect to={this.state.pathName}/>
            )}/>

            <Route exact strict path="/" render={() => (
              <div className="App">
                <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  <p>
                    Edit <code>src/App.js</code> and save to reload.
                  </p>
                  <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn React
                  </a>
                </header>
              </div>
            )}/>

            <Route path="/userlistedit" render={() => (
              this.state.isAuthenticated || this.state.sessionIsLoading ? (
                <UserList users={this.state.users} selectedMarket={this.state.selectedMarket} newUser={this.newUser}
                editContact={this.editContact} viewUser={this.viewUser} deleteUser={this.deleteUser}/>
                ) : (
                  <Redirect to="/login"/>
                )
            )}/>

            <Route path="/edituseradmin" render={() => (
              this.state.isAuthenticated || this.state.sessionIsLoading ? (
                <EditUserAdmin users={this.state.users} selectedUser={this.state.selectedUser} newUser={this.newUser}
                editContact={this.editContact} viewUser={this.viewUser} editUser={this.editUser} deleteUser={this.deleteUser}/>
                ) : (
                  <Redirect to="/login"/>
                )
            )}/>

            <Route path="/login" render={() => (
              <Login sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} signInUser={this.signInUser} isAuthenticated={this.state.isAuthenticated}/>
            )}/>

            <Route path="/documentlist" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                  <DocumentListAdmin documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} markets={this.state.markets} selectedMarket={this.state.selectedMarket} newMarket={this.newMarket} newDocument={this.newDocument}
                  editMarket={this.editMarket} viewMarket={this.viewMarket} viewDocument={this.viewDocument} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} userFirstName={this.state.userFirstName}/>
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/editdocumentlist" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                  <EditDocumentList documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} markets={this.state.markets} selectedMarket={this.state.selectedMarket} newMarket={this.newMarket} newDocument={this.newDocument}
                  editMarket={this.editMarket} viewPage={this.viewPage} viewMarket={this.viewMarket} viewDocument={this.viewDocument} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} userFirstName={this.state.userFirstName} nodes={this.state.nodes}/>
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/viewdocumentlist" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                  <ViewDocumentList userType={this.state.userType} documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} markets={this.state.markets} selectedMarket={this.state.selectedMarket} newMarket={this.newMarket} newDocument={this.newDocument}
                  selectedDocument={this.selectedDocument} selectedPage={this.selectedPage} editMarket={this.editMarket} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} userFirstName={this.state.userFirstName}/>
              ) : (
                <Redirect to="/login"/>
              )
            )}/>


            <Route path="/pagelist" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                  <PageList pages={this.state.pages} documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} markets={this.state.markets} selectedMarket={this.state.selectedMarket} newMarket={this.newMarket} newPage={this.newPage} newDocument={this.newDocument}
                  editMarket={this.editMarket} viewPage={this.viewPage} viewDocument={this.viewDocument} deleteMarket={this.deleteMarket} deletePage={this.deletePage} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail}/>
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/editdocumentadmin" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                this.state.selectedDocument._id !== undefined ? (
                  <EditDocumentAdmin documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} pages={this.state.pages} selectedDocument={this.state.selectedDocument} newPage={this.newPage} newDocument={this.newDocument}
                  editDocument={this.editDocument} editMarket={this.editMarket} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} deletePage={this.deletePage} sendEMail={this.sendEMail}/>
                ) : (<Redirect to="/viewdocumentlist"/>)
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/editpageadmin" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                this.state.selectedPage._id !== undefined ? (
                  <EditPageAdmin documents={this.state.documents} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} pages={this.state.pages} selectedDocument={this.state.selectedDocument} selectedPage={this.state.selectedPage} newMarket={this.newMarket} newDocument={this.newDocument} newChildPage={this.newChildPage}
                   editMarket={this.editMarket} editPage={this.editPage} editDocument={this.editDocument} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} deletePage={this.deletePage} sendEMail={this.sendEMail}/>
                ) : (<Redirect to="/viewdocumentlist"/>)
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/viewdocument" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                this.state.selectedPage._id !== undefined ? (
                  <ViewDocument documents={this.state.documents} comments={this.state.comments} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} pages={this.state.pages} selectedDocument={this.state.selectedDocument} selectedPage={this.state.selectedPage} newMarket={this.newMarket} newDocument={this.newDocument} newChildPage={this.newChildPage} userFirstName={this.state.userFirstName} userLastName={this.state.userLastName} userTeam={this.state.userTeam} newChildComment={this.newChildComment} documentPDF={this.state.documentPDF} getPDFWithId={this.getPDFWithId}
                  editMarket={this.editMarket} editPage={this.editPage} editDocument={this.editDocument} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} newComment={this.newComment}/>
                ) : (<Redirect to="/viewdocumentlist"/>)
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/editdocument" render={() => (
              this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                this.state.selectedPage._id !== undefined ? (
// q: can you identify what in this EditDocument block is not being used in the EditDocument file?
// a: the following are not being used: this.state.selectedPage, this.state.selectedDocument, this.state.selectedContent, this.state.selectedUser, this.state.selectedMarket, this.state.selectedDeliverable, this.state.selectedContact
                  <EditDocument documents={this.state.documents} comments={this.state.comments} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} pages={this.state.pages} selectedDocument={this.state.selectedDocument} selectedPage={this.state.selectedPage} newMarket={this.newMarket} newDocument={this.newDocument} newChildPage={this.newChildPage} userFirstName={this.state.userFirstName} userLastName={this.state.userLastName} userTeam={this.state.userTeam}
                  editMarket={this.editMarket} editPage={this.editPage} updatePage={this.updatePage} editDocument={this.editDocument} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} newComment={this.newComment}
                  deletePage={this.deletePage} changeChildPageOrder={this.changeChildPageOrder}/>
                ) : (<Redirect to="/viewdocumentlist"/>)
              ) : (
                <Redirect to="/login"/>
              )
            )}/>

            <Route path="/editpage/:page_id" render={(props) => (
                this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
                    <EditDocumentNew
                        page_id={props.match.params.page_id}
                        documents={this.state.documents}
                        comments={this.state.comments}
                        pages={this.state.pages}
                        incorrectPasswordUser={this.state.incorrectPasswordUser}
                        isAuthenticated={this.state.isAuthenticated}
                        sessionIsLoading={this.state.sessionIsLoading}
                        isLoading={this.state.isLoading}
                        newChildPage={this.newChildPage}
                        addChildPage={this.addChildPage}
                        editPage={this.editPage}
                        changeChildPageOrder={this.changeChildPageOrder}
                        deletePage={this.deletePage}
                        newComment={this.newComment}
                        newChildComment={this.newChildComment}
                        userOrganization={this.state.userOrganization}
                        userTeam={this.state.userTeam}
                        userFirstName={this.state.userFirstName}
                        viewPage={this.viewPage}

                        removeChildPage={this.removeChildPage}
                    />
                ) : (
                    <Redirect to="/login"/>
                )
            )}/>

            <Route path="/reactflow" render={() => (
            this.state.isAuthenticated || this.state.isLoading || this.state.sessionIsLoading ? (
              this.state.selectedPage._id !== undefined ? (
                <div style={{ height: 800 }}>
                <ReactFlowWrapper documents={this.state.documents} comments={this.state.comments} incorrectPasswordUser={this.state.incorrectPasswordUser} isAuthenticated={this.state.isAuthenticated} sessionIsLoading={this.state.sessionIsLoading} isLoading={this.state.isLoading} pages={this.state.pages} selectedDocument={this.state.selectedDocument} selectedPage={this.state.selectedPage} newMarket={this.newMarket} newDocument={this.newDocument} newChildPage={this.newChildPage} userFirstName={this.state.userFirstName} userLastName={this.state.userLastName} userTeam={this.state.userTeam}
                editMarket={this.editMarket} editPage={this.editPage} updatePage={this.updatePage} editDocument={this.editDocument} viewMarket={this.viewMarket} viewDocument={this.viewDocument} viewPage={this.viewPage} deleteMarket={this.deleteMarket} deleteDocument={this.deleteDocument} sendEMail={this.sendEMail} userOrganization={this.state.userOrganization} newComment={this.newComment}
                deletePage={this.deletePage} changeChildPageOrder={this.changeChildPageOrder} nodes={this.state.nodes} />
                </div>
              ) : (<Redirect to="/viewdocumentlist"/>)
            ) : (
              <Redirect to="/login"/>
            )
            )}/>
        </div>
      </Router>
    )
  }
}

export default App;
