//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import * as DocumentsAPI from '../utils/DocumentsAPI';
import * as PagesAPI from '../utils/PagesAPI';
import * as CommentsAPI from '../utils/CommentsAPI';
import {getFromStorage, setInStorage, removeItem} from '../utils/storage.js'

  export function authenticate(){
    this.setState({isAuthenticated: "true"})

  }


  export function signInUser(eMail, password){
    this.setState({isLoading: true})

    ContactsAPI.signInUser(eMail, password).then((session) => {
      console.log(`new session: ${JSON.stringify(session)}`);

      if(session.success !== false){

        setInStorage('the_main_app', {sessionID: session._id, token: session.userID, jwt: session.userToken, userType: session.userType, userOrganization: session.userOrganization, userTeam: session.userTeam, userFirstName: session.userFirstName, userLastName: session.userLastName, timeStamp: session.timeStamp})
        //fakeAuth.authenticate()
        console.log(`session ${JSON.stringify(session)}`)
        console.log(`session is loading ${this.state.isLoading}`)
        console.log(`is Authenticated: 1 ${this.state.isAuthenticated}`)
        //have to authenticate first before setting isLoading
        this.authenticate()
        this.setState({
          isLoading: false,
          userType: session.userType,
          userOrganization: session.userOrganization,
          userTeam: session.userTeam,
          userFirstName: session.userFirstName,
          userLastName: session.userLastName,

        })
        //this.setState({isLoading: false})
        //console.log('hellow form inside signIn API is Loading', this.state.isLoading)
        //this.forceUpdate()
        //this.myForceUpdate()
        console.log('hellow form inside signIn API')
        console.log(`is Authenticated: 2 ${this.state.isAuthenticated}`)
        //return "Success ya know!!!!!"
        //console.log(fakeAuth.isAuthenticated)

        const obj= getFromStorage('the_main_app')

        if (obj && obj.sessionID){

          ContactsAPI.getUsers(obj.jwt).then((contacts) => {
            console.log(`contacts downloaded: ${JSON.stringify(contacts)}`);
              this.setState({users: contacts});
          });

          DocumentsAPI.getDocuments(obj.jwt).then((docs) => {
            console.log(`Documents downloaded: ${JSON.stringify(docs)}`);
              this.setState({documents: docs});
          });

          PagesAPI.getPages(obj.jwt).then((pages) => {
            console.log(`Pages downloaded: ${JSON.stringify(pages)}`);
              this.setState({pages: pages});
          });

          CommentsAPI.getComments(obj.jwt).then((comments) => {
            console.log(`Comments downloaded: ${JSON.stringify(comments)}`);
              comments.reverse();
              this.setState({comments: comments});
          });
        }

          return "Success ya know!!!!!"

      }else{
        //this.setState({isLoading: false})
        this.setState({incorrectPasswordUser: true})
      }

    });
  }

  export function logOut(){

    removeItem('the_main_app')
    //have to set isLoading to false first
    this.setState({isLoading: false})
    this.setState({isAuthenticated: false})



    ;

  }




  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
