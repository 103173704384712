//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {useEffect, useState} from 'react';
import {Image, InputGroup, Jumbotron, Button, Table, ButtonToolbar, Form, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal, Card, Tab, Tabs} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';
// import Quill from 'quill';//Base library of Quill
import { useQuill } from 'react-quilljs';//React implementation
// import { ImageResize } from 'quill-image-resize';//Quill image resizing addon

function EditDocument (props){
    const [childPageSelectionText, setChildPageSelectionText] = useState("");
    const [showPage, setShowPage] = useState(false);
    const [selectedPage, setSelectedPage] = useState('');
    const [childPageOrder, setChildPageOrder] = useState(0);
    const [commentPost, setCommentPost] = useState('');
    const [pageSelectionText, setPageSelectionText] = useState("");
    const {quill, quillRef} = useQuill();

    // Quill.register('modules/imageResize', ImageResize);

    useEffect(() => {
        if (quill) {
            quill.getModule("toolbar").addHandler("image", function (value) {
                if(value) {
                    var index = quill.getSelection(false).index;
                    window.cloudinary.openUploadWidget(
                        { cloud_name: 'dls7qo9yz',
                            upload_preset: 'kl4zmvwb',
                            showAdvancedOptions: true,
                            cropping: true,
                            maxImageWidth: 800,
                            // tags: ['miniflix'],
                            sources: ['local' /*'url', 'google_photos', 'facebook', 'image_search'*/]
                        },
                        function(error, result) {
                            console.log("This is the result of the last upload", result);
                            if(result !== undefined && result.event === "success"){
                                quill.insertEmbed(index, 'image', result.info.url);
                            } else {
                                console.log("error with image");
                            }
                        }
                    )
                } else {
                    console.log("Image doesn't have value");
                }
            })
            quill.clipboard.dangerouslyPasteHTML(props.selectedPage.content);
            quill.on('text-change', (delta, oldDelta, source) => {
                props.selectedPage.content = (quill.root.innerHTML);
                console.log("text:" + props.selectedPage.content);
            });
        }
    }, [quill]);

    const childPagesLength = (page) => {
        console.log("Content length: " + page.childPages.length)
        var options = [];
        for (var i = 1; i < page.childPages.length + 1; i++) {
        options.push(<option key={i}>{i}</option>);
        }

        return (options)
    }

    const handlePageShow = (page) => {
        setShowPage(false);
        setSelectedPage(page);
    }

    const handlePageClose = () => {
        setShowPage(false);
    }

    const handlePageCloseAndDelete = (pageID) => {
        setShowPage(false);
        props.deletePage(pageID)
    }

    const handlePageSelectionSubmit = () => {
        props.editPage(props.selectedPage, pageSelectionText, props.eventPageParentDocID, props.eventPageParentPageID, pageSelectionText, props.selectedPage.content)
        setPageSelectionText("");
    }

    const handlePageContentSubmit = () => {
        props.editPage(props.selectedPage, pageSelectionText, props.eventPageParentDocID, props.eventPageParentPageID, pageSelectionText, props.selectedPage.content)
    }

    const handleChildPageOrderChange = (event) => {
        setChildPageOrder(event.target.value);
        console.log('onSelect', childPageOrder)
    }

    const handleCommentPostChange = (event) => {
        setCommentPost(event.target.value);
        console.log('onType', commentPost)
    }

    const handlePageChange = (page) => {
        props.viewPage(page._id);
        // quill.setContents()
        const content = page.content ?? ""
        quill.clipboard.dangerouslyPasteHTML(content);
    }

    const handleCommentPostSubmit = (userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost) => {
        props.newComment(userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);
        setCommentPost("");
    }

    // Rendering
    console.log(`marketlistedit props ${props}`)
    console.log('Props', props)

    if(props.isLoading && props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else{
      if(props.isLoading  || props.sessionIsLoading){
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>turbinerunner is Loading</h1>
            </Jumbotron>
          </div>
        )
      }
    }

    console.log('Props', props)

    return(
      <div className="viewPage">
        <Container>
        {selectedPage.pageParentPageID === undefined || selectedPage.pageParentPageID === "firstpage"
            ?
              <div className="text-left">
                <br/>
                <LinkContainer to="/editdocumentlist">
                  <Button bsstyle="success">Back to Document List</Button>
                </LinkContainer>
                <br/>
                <br/>
              </div>
            :
              <div className="text-left">
                  <Button onClick={() => props.viewPage(props.selectedPage.pageParentPageID)} bsstyle="success">Previous Page</Button>
                  <br/>
                  <br/>
              </div>
        }

        <Row  className="Testing5">
          {props.selectedPage.pageParentPageID === undefined || props.selectedPage.pageParentPageID === "firstpage"
              ?
                <br/>
              :
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p><b>You selected: </b>{props.selectedPage.pageSelection}</p>
                    {/* <p className="text-left"><b>You selected: </b></p> */}
                    <form >
                      <FormGroup  controlId="formControlsTextarea">
                          <FormControl value={pageSelectionText} onChange={(event) => setPageSelectionText(event.target.value)}  placeholder="Enter page selection text" style={{ height: 60 }}/><br/>
                          <Button onClick={() => handlePageSelectionSubmit()} bsstyle="success">Update Page Selection Text</Button><br/><br/>
                      </FormGroup>
                    </form>
                </Col>
          }
        </Row>

        {props.documents.filter(doc => doc._id === props.selectedPage.pageParentDocID).map((doc, iA) => (
          <div key={iA}>
            {props.pages.filter(page => page._id === props.selectedPage._id).map((page, iB) => (
              <div key={iB}>
                  <Row className="Testing8 Hero-banner"  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h5>** Edit Document **</h5>
                      <h2>Document: {doc.title}</h2>
                      <p>Document ID: {doc._id}</p>
                      <p>Page ID: {page._id}</p>
                      <p>Turbine Model: {doc.turbineModel}</p>
                      <p>Selected Page: {page.pageSelection}</p>
                    </Col>
                  </Row>

                  <div style={{ width:"auto", height: 800 }}>
                      <div ref={quillRef} />
                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <Button onClick={() => handlePageContentSubmit()} bsstyle="success">Update Page Content</Button><br/><br/>
                  <Row className="Testing8"  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h4>Make a selection below:</h4>
                    </Col>
                  </Row>
                  <br/>
                  <form >
                    <FormGroup  controlId="formControlsTextarea">
                        <FormControl value={childPageSelectionText} onChange={(event) => setChildPageSelectionText(event.target.value)} placeholder="Enter Child Page selection text" style={{ height: 30 }}/><br/>
                        <Button onClick={() =>{ props.newChildPage(childPageSelectionText, props.selectedPage.pageParentDocID, props.selectedPage._id, childPageSelectionText); setChildPageSelectionText("")}} bsstyle="success">New Child Page</Button><br/><br/>
                    </FormGroup>
                  </form>
                  <Row className="Testing2"  >
                    <Col xs={1} sm={1} md={1} lg={2}>
                      <h6>#</h6>
                    </Col>
                    <Col xs={8} sm={9} md={9} lg={8}>
                      <h6 className="text-left">Page Selection</h6>
                    </Col>
                    <Col className="text-left" xs={3} sm={2} md={2} lg={2}>
                      <h6>Action</h6>
                    </Col>
                  </Row>
                  {props.selectedPage.childPages !== undefined && props.selectedPage.childPages.length !== 0
                      ?
                          props.selectedPage.childPages.map((value, ia) => (
                            props.pages.filter(page => page._id !== 0 && page._id === value).map((page, ib) => (
                              <Row key={page._id} className="Testing5">
                                <Col xs={2} sm={2} md={2} lg={2}>
                                  <form >
                                    <FormGroup  controlId="formControlsTextarea">
                                      <Form.Control defaultValue={ia + 1} onChange={handleChildPageOrderChange} as="select">
                                        {childPagesLength(props.selectedPage)}
                                      </Form.Control>
                                    </FormGroup>
                                  </form>
                                  <Button onClick={() => props.changeChildPageOrder(props.selectedPage, page, ia, childPageOrder)} bsstyle="success">update order</Button>
                                </Col>
                                <Col xs={8} sm={8} md={8} lg={8}>
                                <p className="text-left">{page.pageSelection}</p>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2}>
                                    <Button onClick={() => handlePageChange(page._id)} bsstyle="success">Edit</Button>
                                    <Button variant="danger" onClick={() => handlePageShow(page)}>
                                      Delete
                                    </Button>
                                </Col>
                                <Modal show={showPage} onHide={handlePageClose}>
                                 <Modal.Header closeButton>
                                   <Modal.Title>Delete Page</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>Warning!!! Are you sure you want to delete the page "{selectedPage.pageSelection}"?</Modal.Body>
                                 <Modal.Footer>
                                   <Button variant="secondary" onClick={handlePageClose}>
                                     Close
                                   </Button>
                                   <Button variant="danger" onClick={() => handlePageCloseAndDelete(selectedPage._id)}>
                                     Delete
                                   </Button>
                                 </Modal.Footer>
                               </Modal>
                              </Row>
                            )
                        )))
                      :
                          <Row className="Testing">
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <h5>Currently no Child Pages</h5>
                            </Col>
                          </Row>
                    }
                </div>
            ))}
          </div>
        ))}
        <br/>
        <br/>
        <br/>
        <Tabs defaultActiveKey="organization" id="uncontrolled-tab-example">
          <Tab eventKey="organization" title={props.userOrganization}>
            <br/>
            <form >
              <Form.Group controlId="formControlsTextarea">
                <Form.Label>Post Comment</Form.Label>
                <Form.Control onChange={handleCommentPostChange} as="textarea" value={commentPost} placeholder="Enter a comment about this document" rows="3" />
              </Form.Group>
              <Button onClick={() => handleCommentPostSubmit(props.userOrganization, props.userTeam, props.userFirstName, props.selectedDocument, props.selectedPage._id, commentPost)} bsstyle="success">Submit New Comment</Button>
            </form>
            <br/>
            <br/>
            {props.selectedPage.pageParentPageID === "firstpage"
                ?
                    props.comments.filter(comment => comment.commentParentDocID === props.selectedDocument).map((comment, iA) => (
                      <div key={iA}>
                      <Row className="Testing" >
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <h5 className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</h5>
                          <p className="text-left">{comment.commentUser + ": " + comment.commentText}</p>
                        </Col>
                      </Row>
                          {comment.childComments !== undefined && comment.childComments.length !== 0
                              ?
                                comment.childComments.map((value, ia) => (
                                    props.comments.filter(comment => comment._id !== 0 && comment._id === value).map((comment, ib) => (
                                      <Row key={ia} className="Testing">
                                        <Col xs={12} sm={12} md={12} lg={1}>

                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={11}>
                                        <h5 className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</h5>
                                        <p className="text-left">{comment.commentUser + ": " + comment.commentText}</p>
                                        </Col>
                                      </Row>
                                    )
                                )))
                              :
                                  <Row className="Testing">
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <p>Currently no replies</p>
                                    </Col>
                                  </Row>
                        }
                      </div>
                    ))
                :
                  props.comments.filter(comment => comment.commentParentPageID === props.selectedPage._id).map((comment, iA) => (
                      <Row className="Testing" key={iA}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                        <h5 className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</h5>
                        <p className="text-left">{comment.commentUser + ": " + comment.commentText}</p>
                        </Col>
                      </Row>
                  ))
            }
          </Tab>
          <Tab eventKey="public" title="Public">
          </Tab>
        </Tabs>
        </Container>
      </div>
    )
}
export default EditDocument;
