//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class PageList extends Component{
  state = {
    query: '',
    eventFirstName: '',
    eventEMail: '',
    show: false,
    selectedPage: '',
    eventPageTitle: '',
    eventPageSelectionText: '',
    eventPageParentDocID: '',
    eventPageParentPageID: '',

  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){
      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  //show = useState(false);

  //handleClose = () => setShow(false);
  //handleShow = () => setShow(true);

  handleShow = (page) => {
    this.setState(
      {
        show: true,
        selectedPage: page,
      });
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleCloseAndDelete = (pageID) => {

    this.setState({show: false});
    this.props.deletePage(pageID)
  }

  handlePageTitle = (event) => {

    this.setState({eventPageTitle: event.target.value})
    console.log('onType', this.state.eventPageTitle)
  }

  handlePageParentDocID = (event) => {

    this.setState({eventPageParentDocID: event.target.value})
    console.log('onType', this.state.eventPageParentDocID)
  }

  handlePageParentPageID = (event) => {

    this.setState({eventPageParentPageID: event.target.value})
    console.log('onType', this.state.eventPageParentPageID)
  }

  handlePageSelectionText = (event) => {

    this.setState({eventPageSelectionText: event.target.value})
    console.log('onType', this.state.eventPageSelectionText)
  }

  handleEMailChange = (event) => {

    this.setState({eventEMail: event.target.value})
    console.log('onType', this.state.eventEMail)
  }

/*
  onSubmitHandle(event) {
  event.preventDefault();
  this.setState({

  });
  console.log('tsting')
  event.target.item.value = '';
}*/


  render(){
    console.log(`marketlistedit props ${this.props}`)
    console.log('Props', this.props)


    if(this.props.isLoading && this.props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else{

      if(this.props.isLoading  || this.props.sessionIsLoading){
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>CW-Proj is Loading</h1>
            </Jumbotron>
          </div>
        )
      }
    }

    /*if(!this.props.isAuthenticated){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>you are not logged in go back</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">login</Button>
          </LinkContainer>
        </div>
      )
    }*/
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredPages = this.props.pages.filter(
      (page) => {

        if(page.pageTitle !== undefined){
          return page.pageTitle.toLowerCase().indexOf(this.state.query) !== -1
        }

      }
    )


    return(

      <div className="viewPage">
        <Jumbotron>
          <h1>TurbineRunner Dashboard</h1>
        </Jumbotron>

        <Container>
        <div>
        <form >
          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Page Title</FormLabel>
              <FormControl onChange={this.handlePageTitle}  placeholder="Enter Page Title" style={{ height: 30 }}/>
              <FormLabel>Page Parent Doc ID</FormLabel>
              <FormControl onChange={this.handlePageParentDocID}  placeholder="Enter Page Paren Doc ID" style={{ height: 30 }}/>
              <FormLabel>Page Parent Page ID</FormLabel>
              <FormControl onChange={this.handlePageParentPageID}  placeholder="Enter Page Parent Page ID" style={{ height: 30 }}/>
              <FormLabel>Page Selection Text</FormLabel>
              <FormControl onChange={this.handlePageSelectionText}  placeholder="Enter Page Selection Text" style={{ height: 30 }}/>
          </FormGroup>
          <Button onClick={() => this.props.newPage(this.state.eventPageTitle, this.state.eventPageParentDocID, this.state.eventPageParentPageID, this.state.eventPageSelectionText )} bsstyle="success">Submit New Page</Button>
        </form>
        <br/>
        <br/>
        </div>
          <Row className="Testing2"  >
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="formControlsTextarea2">
                  <FormLabel>Search</FormLabel>

                  <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Page Title" />

              </FormGroup>
            </Col>
          </Row>
          <Row className="Testing2"  >
            <Col xs={1} sm={1} md={1} lg={1}>
              <h6>#</h6>
            </Col>
            <Col xs={3} sm={1} md={1} lg={1}>
              <h6>Page ID</h6>
            </Col>
            <Col xs={2} sm={1} md={1} lg={1}>
              <h6>Page Parent Doc ID</h6>
            </Col>
            <Col xs={3} sm={1} md={1} lg={1}>
              <h6>Parent Page ID</h6>
            </Col>
            <Col className="d-none d-sm-block" sm={6} md={6} lg={6}>
              <h6>Page Selection</h6>
            </Col>
            <Col xs={3} sm={2} md={2} lg={2}>
              <h6>Action</h6>
            </Col>
          </Row>

            {filteredPages.filter(page => page._id !== 0 && page._id !== "template").map((page, i) => (

              <Row className="Testing" key={i}>
                <Col xs={1} sm={1} md={1} lg={1}>
                  <p>{i + 1}</p>
                </Col>
                <Col xs={3} sm={1} md={1} lg={1}>
                  <p>{page._id !== undefined ? page._id.substring(page._id.length - 5, page._id.length) : "No Page ID"}</p>
                  {/* <p>{page._id}</p>  */}
                </Col>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <p>{page.pageParentDocID !== undefined ? page.pageParentDocID.substring(page.pageParentDocID.length - 5, page.pageParentDocID.length) : "No Parent Doc ID"}</p>
                </Col>
                <Col xs={3} sm={1} md={1} lg={1}>
                  <p>{page.pageParentPageID !== undefined ? page.pageParentPageID.substring(page.pageParentPageID.length - 5, page.pageParentPageID.length) : "No Parent Page ID"}</p>
                </Col>
                <Col className="d-none d-sm-block" sm={6} md={6} lg={6}>
                  <p>{page.pageSelection}</p>
                </Col>
                <Col xs={3} sm={2} md={2} lg={2}>
                  {/*<LinkContainer to="/marketview">
                    <Button onClick={() => this.props.viewMarket(market._id)} bsstyle="success">View</Button>
                  </LinkContainer>&nbsp;&nbsp;*/}
                  <LinkContainer to="/editpageadmin">
                    <Button onClick={() => this.props.viewPage(page._id)} bsstyle="success">Edit</Button>
                  </LinkContainer>&nbsp;&nbsp;
                  <Button variant="danger" onClick={() => this.handleShow(page)}>
                    Delete
                  </Button>
                </Col>

                <Modal show={this.state.show} onHide={this.handleClose}>
                 <Modal.Header closeButton>
                   <Modal.Title>Delete Page</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>Warning!!! Are you sure you want to delete the page "{this.state.selectedPage.pageTitle}"?</Modal.Body>
                 <Modal.Footer>
                   <Button variant="secondary" onClick={this.handleClose}>
                     Close
                   </Button>
                   <Button variant="danger" onClick={() => this.handleCloseAndDelete(this.state.selectedPage._id)}>
                     Delete
                   </Button>
                 </Modal.Footer>
               </Modal>

              </Row>
              ))}

            </Container>

      </div>
    )
  }
}
export default PageList;
