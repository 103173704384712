//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6

import {setInStorage} from './storage.js'
import {fakeAuth} from './Authenticate_t.js'
import * as Const from './Constants'

/*********************** Users ***************************/

export const getUsers = (jwt) =>
  fetch(`${Const.API}/user`,{
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const getUserWithId = (jwt, id) =>
  fetch(`${Const.API}/user/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const newUser = (jwt, user) =>
  fetch(`${Const.API}/user`, {
    method: "POST",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )


export const updateUser = (jwt, id, user) =>
  fetch(`${Const.API}/user/${id}`, {
    method: "PUT",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const deleteUser = (jwt, id) =>
  fetch(`${Const.API}/user/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )


export const sendEMail = (id) =>
  fetch(`${Const.API}/sendEMail/${id}`, {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )


export const signIn = (email, password) => (
  // this.setState({isLoading: true}), //this seemed to not work here
  fetch(`${Const.API}/signin1`, {
    method: 'POST',
    headers: {
      ...Const.HEADERS,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email,
      password: password
    }),
  }).then(res => res.json())
     .then(json => {
       console.log('json', json)
       if(json.success){
         setInStorage('the_main_app', {token: json.token, permissions: json.permissions})
         fakeAuth.authenticate()
         //this.setState({isLoading: false})
         console.log('hellow form inside signIn API is Loading', this.state.isLoading)
         //this.forceUpdate()
         this.myForceUpdate()
         console.log('hellow form inside signIn API')
         return "Success ya know!!!!!"
         //console.log(fakeAuth.isAuthenticated)
       }
     })

   )

 export const signInUser = (email, password) =>
   fetch(`${Const.API}/signinuser`, {
     method: "POST",
     body: JSON.stringify({
        userEMail: email,
        userPassword: password
      }),
     headers: {
       "Content-Type": "application/json",
     }
   }).then(
     function(response){
       if(response.status !== 200){
         console.log("Something went wrong" + response.status);
         return;
       }

       return response.json();
     }
   )


 export const verifyUser = (userSessionId) =>
     fetch(`${Const.API}/verifyusersession/${userSessionId}`)
     .then(res => res.json())
     .then((res) => {
         if(res.success) {
           console.log("printing userSessionId", userSessionId)
           console.log("verifyresponse", res)
           //fakeAuth.authenticate()

           //console.log("check authentication in verify", fakeAuth.isAuthenticated)
           return res
         }
         console.log('###### Looks like there was a problem. Status Code: ' +
         res.status);

         return;
       }
     )


  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
