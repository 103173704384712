import React, {useEffect, useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import {Image, InputGroup, Jumbotron, Button, DropdownButton, Dropdown, Table, ButtonToolbar, Form, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal, Card, Tab, Tabs} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';
// import Quill from 'quill';//Base library of Quill
import { useQuill } from 'react-quilljs';
//React implementation
// import { ImageResize } from 'quill-image-resize';//Quill image resizing addon

function EditDocumentNew (props){
//States
    const [pageId, setPageId] = useState(props.page_id);
    const [isParentPage, setIsParentPage] = useState(false);
    const [page, setPage] = useState({
        _id: "",
        pageTitle: "",
        pageParentDocID: "",
        pageSelection: "",
        childPages: [{}],
        content: ""
    });
    const [pageSelectionText, setPageSelectionText] = useState((page) ? page.pageSelection : "");
    const [pageSelectionTextSubmited, setPageSelectionTextSubmited] = useState((page) ? page.pageSelection : "");
    const [parentPageId, setParentPageId] = useState("");
    const [pageContent, setPageContent] = useState("");
    const [docId, setDocId] = useState("");
    const [doc, setDoc] = useState({
        _id: "",
        title: "",
        alarmCode: "",
        tools: [],
        parts: [],
        firstNodeId: "",
        firstPageId: ""
    });
    const [childPageSelectionText, setChildPageSelectionText] = useState("");
    const contentChangedPageId = useRef("");//don't need a full state, just a var
    const [childPageOrder, setChildPageOrder] = useState(0);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    
    const [showCantDeleteConfirm, setShowCantDeleteConfirm] = useState(false);
   
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
    const [selectedPage, setSelectedPage] = useState("");

    const [selectedChildPage, setSelectedChildPage] = useState("");

    const [commentPost, setCommentPost] = useState("");
    const [commentChildPost, setCommentChildPost] = useState('');
    const [showCommentReplyForId, setShowCommentReplyForId] = useState("");


    //testing for existing page selection
    const [selectedExistingPage, setSelectedExistingPage] = useState((page) ? page : "");

//initialize libraries to variables
    const {quill, quillRef} = useQuill();
    const history = useHistory();

// Setup page and doc states
    useEffect(() => {
        //scroll to the top of the window every time the page is loaded
        window.scrollTo(0, 0);
        
        if(props.documents.length < 1 || props.pages.length < 1) {
            return;
        }
        const p = props.pages.find(function(p) {
            return p._id === pageId;
        });
        setPage(p);
        setPageContent(p.content);
        setParentPageId(p.pageParentPageID);
        setDocId(p.pageParentDocID);
        const d = props.documents.find(function(d) {
            return d._id === p.pageParentDocID;
        });
        setDoc(d);
        setPageSelectionTextSubmited(p.pageSelection);
        console.log("Edit Page is loaded!")
    }, [pageId, props.pages, props.documents]);
    useEffect(() => {
        setIsParentPage(parentPageId === undefined || parentPageId === "firstpage");
    }, [page, parentPageId])

// Setup Quill. Load only once.
    useEffect(() => {
        if(quill) {
            quill.getModule("toolbar").addHandler("image", function (value) {
                if(value) {
                    var index = quill.getSelection(false).index;
                    window.cloudinary.openUploadWidget(
                        { cloud_name: 'dls7qo9yz',
                            upload_preset: 'kl4zmvwb',
                            showAdvancedOptions: true,
                            cropping: true,
                            maxImageWidth: 800,
                            // tags: ['miniflix'],
                            sources: ['local' /*'url', 'google_photos', 'facebook', 'image_search'*/]
                        },
                        function(error, result) {
                            console.log("This is the result of the last upload", result);
                            if(result !== undefined && result.event === "success"){
                                quill.insertEmbed(index, 'image', result.info.url);
                            } else {
                                console.log("error with image");
                            }
                        }
                    )
                } else {
                    console.log("Image doesn't have value");
                }
            })

            quill.on('text-change', (delta, oldDelta, source) => {
                if(contentChangedPageId.current === pageId) {
                    setPageContent(quill.root.innerHTML);
                } else {
                    contentChangedPageId.current = pageId;
                }
            });
        }
    },[quill]);//load only once


// Setup Quill content on initial page load
    useEffect(() => {
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(pageContent);
        }
    }, [quill, page]);

//Handlers
    const handlePageSelectionSubmit = () => {
        props.editPage(page, pageSelectionText, docId, parentPageId, pageSelectionText, pageContent);
        setPageSelectionTextSubmited(pageSelectionText);
    }

    const handlePageContentSubmit = () => {
        props.editPage(page, pageSelectionText, docId, parentPageId, pageSelectionText, pageContent);
    }

    //this is the function that will be called when the user selects an existing page from a the dropdown menu to add as a child page
    const handleSelectExistingPage = (existingPageId) => {

        console.log("existingPageId: " + existingPageId);

        const tempPages = props.pages;

        const foundPage = tempPages.find(p => p._id === existingPageId); // Find the page directly without mapping

        if (foundPage) {
        
            console.log("Found the existing page we selected: " + JSON.stringify(foundPage));
        
            setSelectedExistingPage(foundPage); // Set the state

            console.log("selectedExistingPage is now set" + selectedExistingPage._id);

        } else {
            console.log("No page found with the ID: " + existingPageId);
        }

    }

    //This is the function that will be called when the user clicks the button to add the selected page as a child page
    const handleAddExistingPageAsChildPage = (existingPageId) => {
        // Assuming 'existingPageId' is the ID of the page you want to add as a child
        // Check if the existingPageId is already a child of the current page to avoid duplicates
        
        console.log("existingPageId: " + existingPageId);

        const tempPages = props.pages;

        const foundPage = tempPages.find(p => p._id === existingPageId); // Find the page directly without mapping

        if (foundPage) {
        
            console.log("Found the existing page we selected: " + JSON.stringify(foundPage));
            setSelectedExistingPage("");
        

        } else {
            console.log("No page found with the ID: " + existingPageId);
        }

        console.log("page we want to update: " + page.pageSelection);

        console.log("selectedPage: " + JSON.stringify(page.childPages));
        
        const isAlreadyChild = page.childPages.some(childPage => childPage._id === existingPageId);
        
        if (!isAlreadyChild) {
            // Update the current page's childPages array with the new child page ID
            const updatedChildPages = [...page.childPages, existingPageId];

            console.log("updatedChildPages: " + updatedChildPages);

       // const filteredPages = filteredPages.filter(page => page._id === selectedPage._id);
        
        // Create a new updated page object with the updated list of child page IDs
            const updatedSelectedPage = { ...page, childPages: updatedChildPages };

        // Update the state with the updated page
            setPage(updatedSelectedPage);

        // Call the editPage function to update the backend/database as necessary
            props.editPage(updatedSelectedPage, updatedSelectedPage.pageSelectionText, updatedSelectedPage.docId, updatedSelectedPage.parentPageId, updatedSelectedPage.pageSelectionText, updatedSelectedPage.pageContent);
        } else {
            console.log("This page is already added as a child.");
        }
    };


    const handleLoadPage = (id) => {
        setPageId(id)
        history.push("/editpage/"+id);
    }

    const childPagesLength = (page) => {
        var options = [];
        for (var i = 1; i < page.childPages.length + 1; i++) {
            options.push(<option key={i}>{i}</option>);
        }
        return (options)
    }

    const handleChildPageOrderChange = (event) => {
        setChildPageOrder(event.target.value);
    }

    const handlePageDelete = () => {
        let cPage = selectedChildPage
        setShowDeleteConfirm(false);
        props.deletePage(cPage._id)
    }

    const handleChildPageRemove = () => {
        let cPage = selectedPage
        setShowRemoveConfirm(false);
        //props.deletePage(cPage._id)
        console.log(`the selected page is ${page._id}`)
        props.removeChildPage(cPage._id, page._id);
    }

    const handlePageConfirmDeleteModal = (cPage) => {
        setSelectedChildPage(cPage);

        console.log("cPage ID: " + cPage._id);
        console.log("cPage Parent Page ID: " + cPage.pageParentPageID);
        console.log("Page ID: " + page._id);


        if(cPage.pageParentPageID !== undefined && cPage.pageParentPageID === page._id){ 
            
            console.log("you CAN delete this page: " + cPage._id);
            setShowDeleteConfirm(true);
            

        }else{
            
            console.log(`Child page ${cPage._id} cannot be deleted because it is a link to an existing page.  Use the "Remove Child" button to remove the link.`);
            setShowCantDeleteConfirm(true);
        }

        //setShowDeleteConfirm(true);
    }

    const handlePageConfirmRemoveModal = (cPage) => {
        setSelectedPage(cPage);
        setShowRemoveConfirm(true);
    }

    const handleDeleteModalClose = () => {
        setShowDeleteConfirm(false);
    }

    const handleCantDeleteModalClose = () => {
        setShowCantDeleteConfirm(false);
    }

    const handleRemoveModalClose = () => {
        setShowRemoveConfirm(false);
    }

    const handleCommentPostSubmit = (userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost) => {
        props.newComment(userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);
        setCommentPost("");
    }

    const handleCommentChildPostSubmit = (parentComment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost) => {
        props.newChildComment(parentComment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);
        setCommentChildPost("");
        setShowCommentReplyForId("");
    }

    const handleToggleReply = (comment) => {
        setShowCommentReplyForId((showCommentReplyForId === comment._id) ? "" : comment._id);
    }

//logic for render conditionals
    let backButton;
    if(isParentPage){
        backButton =
            <div className="text-left">
                <br/>
                <LinkContainer to="/editdocumentlist">
                    <Button bsstyle="success">Back to Document List</Button>
                </LinkContainer>&nbsp;&nbsp;
                             
                <br/>
                <br/>
            </div>
    } else {
        backButton =
            <div className="text-left">
                <br/>
                <Button onClick={() => handleLoadPage(parentPageId)} bsstyle="success">Parent Page</Button>&nbsp;&nbsp;

               {/*
               
               // delete button for this page
               <Button variant="danger" onClick={() => handlePageConfirmDeleteModal(page)}>
                    Delete This Page
                    {console.log("selectedPage: " + JSON.stringify(page._id))}
                </Button>
                <Modal show={showDeleteConfirm} onHide={handleDeleteModalClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Warning!!! Are you sure you want to delete the page "{selectedPage.pageSelection}"?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleDeleteModalClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={() => handlePageDelete()}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>*/}
                
                <br/>
                <br/>
            </div>
    }

    let selectedPageHtml = "";
    if(!isParentPage) {
        selectedPageHtml =
            <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <p><b>You selected: </b>{pageSelectionTextSubmited}</p>
                    <form>
                        <FormGroup controlId="formControlsTextarea">
                            <FormControl value={pageSelectionText}
                                         onChange={(event) => setPageSelectionText(event.target.value)}
                                         placeholder="Enter page short descrption / selection text" style={{height: 60}}/><br/>
                            <Button onClick={() => {handlePageSelectionSubmit(); setPageSelectionText("")}} bsstyle="success">Update Page Selection
                                Text</Button><br/><br/>
                        </FormGroup>
                    </form>
                </Col>
            </Row>
    }

    let pageInfoBox =
        <Row className="Testing8 Hero-banner"  >
            <Col xs={12} sm={12} md={12} lg={12}>
                <h5>** Edit Document **</h5>
                <h2>Document: {doc.title}</h2>
                <p>Document ID: {doc._id}</p>
                <p>Page ID: {page._id}</p>
                <p>Turbine Model: {doc.turbineModel}</p>
                <p>Selected Page: {pageSelectionTextSubmited}</p>
            </Col>
        </Row>

    let pageContentBox =
        <div style={{height: "auto"}}>
            <div style={{ width:"auto", height: "auto" }}>
                <div ref={quillRef} />
            </div>
            <br/>
            <Button onClick={() => handlePageContentSubmit()} bsstyle="success">Update Page Content</Button>
            <br/>
            <br/>
        </div>

    let childrenDiv =
        <Row className="Testing">
            <Col xs={12} sm={12} md={12} lg={12}>
                <h5>Currently no Child Pages</h5>
            </Col>
        </Row>
    if(page.childPages !== undefined && page.childPages.length > 0) {
        childrenDiv =
            page.childPages.map((value, ia) => (
                props.pages.filter(cPage => cPage._id !== 0 && cPage._id === value).map((cPage, ib) => (
                        <Row key={cPage._id} className="Testing5">
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <form >
                                    <FormGroup  controlId="formControlsTextarea">
                                        <Form.Control defaultValue={ia + 1} onChange={handleChildPageOrderChange} as="select">
                                            {childPagesLength(page)}
                                        </Form.Control>
                                    </FormGroup>
                                </form>
                                <Button onClick={() => props.changeChildPageOrder(page, cPage, ia, childPageOrder)} bsstyle="success">Update Order</Button>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <p className="text-left">{cPage.pageSelection}</p>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2}>
                                <Button onClick={() => handleLoadPage(cPage._id)} bsstyle="success">Edit Child</Button>
                                <br/><br/>
                                <Button variant="warning" onClick={() => handlePageConfirmRemoveModal(cPage)}>
                                    Remove Child
                                </Button>
                                <br/><br/>
                                <Button variant="danger" onClick={() => handlePageConfirmDeleteModal(cPage)}>
                                    Delete Child
                                </Button>
                            </Col>
                            <Modal show={showCantDeleteConfirm} onHide={handleCantDeleteModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Cant Delete Page</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Child page "{cPage._id}"" cannot be deleted because it is being used in other locations.  Use the "Remove Child" button to remove the child page from this list.</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCantDeleteModalClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={showDeleteConfirm} onHide={handleDeleteModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete Page</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Warning!!! Are you sure you want to delete the page "{selectedPage.pageSelection}"?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleDeleteModalClose}>
                                        Close
                                    </Button>
                                    <Button variant="danger" onClick={() => handlePageDelete()}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            
                            <Modal show={showRemoveConfirm} onHide={handleRemoveModalClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Delete Page</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Warning!!! Are you sure you want to Remove the page "{selectedPage.pageSelection}"?</Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleRemoveModalClose}>
                                        Close
                                    </Button>
                                    <Button variant="danger" onClick={() => handleChildPageRemove()}>
                                        Remove
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Row>
                    )
            )))
    }

    let childrenContainer =
        <div>
            <Row className="Testing8"  >
                <Col xs={12} sm={12} md={12} lg={12}>
                    <h4>Child Pages</h4>
                </Col>
            </Row>
            <br/>
            <h6>Create a new child page for this page by typing a short description or selection text, and then click "Create a new child page"</h6>
            <br/>
            <form >
                <FormGroup  controlId="formControlsTextarea">
                    <FormControl value={childPageSelectionText} onChange={(event) => setChildPageSelectionText(event.target.value)} placeholder="Enter new page short description / selection text" style={{ height: 30 }}/><br/>
                    <Button onClick={() =>{ props.newChildPage(childPageSelectionText, docId, pageId, childPageSelectionText); setChildPageSelectionText("")}} bsstyle="success">Create a new child page</Button><br/><br/>
                </FormGroup>
            </form>
           
            <h6>Or select an existing page from this document to add it as a child page.</h6>
            <br/>
            <DropdownButton id="dropdown-basic-button" title="Existing pages in this document" size="sm" variant="secondary">
                {props.pages.filter(page => page.pageParentDocID === docId).map((page, i) => ( 
                    page.pageSelection !== "firstpage" && (

                        <Dropdown.Item key={i} onClick={() =>{ handleSelectExistingPage(page._id)}}>{page._id.substring(page._id.length - 5, page._id.length) + " - " + page.pageSelection}</Dropdown.Item>
                    
                    )
                ))}

            </DropdownButton>
            <br/>
            
            {selectedExistingPage && selectedExistingPage._id
                ? (
                    <div>
                        <h6>You selected: {selectedExistingPage._id.substring(selectedExistingPage._id.length - 5)} - {selectedExistingPage.pageSelection.substring(0, Math.min(10, selectedExistingPage.pageSelection.length))}</h6><br/>
                        <Button onClick={() =>{ props.addChildPage(page, selectedExistingPage) }} bsstyle="success">Add as child page</Button>
                    </div>
                )
                : (
                    <h6>No existing page selected</h6> // This will be rendered if selectedExistingPage is falsy or _id is an empty string
                )
            }
            
            <br/>
            <Row className="Testing2"  >
                <Col xs={1} sm={1} md={1} lg={2}>
                    <h6>#</h6>
                </Col>
                <Col xs={8} sm={9} md={9} lg={8}>
                    <h6 className="text-left">Description / Page Selection</h6>
                </Col>
                <Col className="text-left" xs={3} sm={2} md={2} lg={2}>
                    <h6>Action</h6>
                </Col>
            </Row>
            {childrenDiv}
        </div>

    const commentReplys = (comment) => {
        return(
            comment.childComments.map((value, ia) => (
                props.comments.filter(comment => comment._id !== 0 && comment._id === value).map((comment, ib) => (
                        <Row key={ia} className="Testing">
                            <Col xs={1} sm={1} md={1} lg={1}></Col>{/* blank line to tab over */}
                            <Col xs={11} sm={11} md={11} lg={11}>
                                <h5 className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</h5>
                                <p className="text-left">{comment.commentUser + ": " + comment.commentText}</p>
                            </Col>
                        </Row>
                    )
                )))
        )
    }

    let commentReplyTextCol = (comment) => {
        if(showCommentReplyForId !== comment._id) {
            return ("");
        }
        return(
            <Col xs={12} sm={12} md={11} lg={11}>
                <Form.Group controlId="formControlsTextarea">
                    <Form.Control onChange={(event) => {setCommentChildPost(event.target.value)}} as="textarea" value={commentChildPost} placeholder="Enter a reply" rows="3"/>
                </Form.Group>
            </Col>
        )
    }

    let commentReplyButtonCol = (comment) => {
        if(showCommentReplyForId !== comment._id) {
            return ("");
        }
        return(
            <Col xs={12} sm={12} md={1} lg={1}>
                <Button onClick={() => handleCommentChildPostSubmit(comment,props.userOrganization, props.userTeam, props.userFirstName, doc, pageId, commentChildPost)} bsstyle="success">Submit Reply</Button>
            </Col>
        )
    }

    let toggleReplyButton  = (comment) => {
        return (
            <Col xs={1} sm={1} md={1} lg={1}>
                <Button onClick={() => handleToggleReply(comment)} bsstyle="success">{(showCommentReplyForId !== comment._id) ? "Reply" : "Cancel"}</Button>
            </Col>
        );
    }

    let commentsContent = () => {
        let commentsList;
        if(isParentPage) {
            commentsList = props.comments.filter(comment => comment.commentParentDocID === docId && comment.commentParentCommentID === "");
        } else {
            commentsList = props.comments.filter(comment => comment.commentParentPageID === pageId && comment.commentParentCommentID === "");
        }
        return(
            commentsList.map((comment, iA) => (
                <div key={iA}>
                    <form>
                        <Row className="Testing">
                            <Col xs={11} sm={11} md={11} lg={11}>
                                <h5 className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</h5>
                            </Col>
                            {toggleReplyButton(comment)}
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <p className="text-left">{comment.commentUser + ": " + comment.commentText}</p>
                            </Col>
                            {commentReplyTextCol(comment)}
                            {commentReplyButtonCol(comment)}
                        </Row>
                    </form>
                    {commentReplys(comment)}
                </div>
            ))
        )
    }

    let commentsTabs =
        <Tabs defaultActiveKey="organization" id="uncontrolled-tab-example">
            <Tab eventKey="organization" title={props.userOrganization}>
                <br/>
                <form >
                    <Form.Group controlId="formControlsTextarea">
                        <Form.Label>Post Comment</Form.Label>
                        <Form.Control onChange={(event) => {setCommentPost(event.target.value)}} as="textarea" value={commentPost} placeholder="Enter a comment about this document" rows="3" />
                    </Form.Group>
                    <Button onClick={() => handleCommentPostSubmit(props.userOrganization, props.userTeam, props.userFirstName, doc, pageId, commentPost)} bsstyle="success">Submit New Comment</Button>
                </form>
                <br/>
                <br/>
                {commentsContent()}
            </Tab>
            <Tab eventKey="public" title="Public">
            </Tab>
        </Tabs>

//don't load page until all loading is complete
    if(props.isLoading && props.incorrectPasswordUser){
        console.log("incorrectPasswordUser");
        return(
            <div className="viewPage">
                <Jumbotron>
                    <h1>You entered an incorrect username or password</h1>
                </Jumbotron>
                <LinkContainer to="/login">
                    <Button  bsStyle="primary">Try Again</Button>
                </LinkContainer>
            </div>
        )
    } else if(props.isLoading  || props.sessionIsLoading || props.documents.length < 1 || props.pages.length < 1 ) {
        console.log("Edit Page Data isLoading");
        return(
            <div className="viewPage">
                <Jumbotron>
                    <h1>turbinerunner is Loading</h1>
                </Jumbotron>
            </div>
        )
    }

//Rendering
    return(
        <div className="viewPage">
            <Container>
                {backButton}
                {selectedPageHtml}
                {pageInfoBox}
                {pageContentBox}
                {childrenContainer}
                <br/>
                <br/>
                <br/>
                {commentsTabs}
            </Container>
        </div>
    )
}
export default EditDocumentNew;