//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import * as DocumentsAPI from '../utils/DocumentsAPI';
import * as PagesAPI from '../utils/PagesAPI';
import * as DocumentPDFAPI from '../utils/DocumentPDFAPI';

import {getFromStorage, setInStorage, removeItem} from '../utils/storage.js'


  // export function newPDF(pdfParentDocID, pdfType, pdfURL, pdfPublicID){
  //
  //   const obj= getFromStorage('the_main_app')
  //
  //   if (obj && obj.sessionID){
  //
  //     DocumentPDFAPI.newPDF(obj.jwt,
  //       {
  //
  //         pdfParentDocID: pdfParentDocID,
  //         pdfType: pdfType,
  //         pdfPublicID: pdfPublicID,
  //         pdfURL: pdfURL,
  //
  //       }).then((pdfResponse) => {
  //         console.log(`new pdf added: ${pdfResponse}`);
  //
  //         var tempPDF = this.state.pdf;
  //
  //         tempContent.push(
  //           {
  //             _id: pdfResponse._id,
  //             pdfParentDocID: pdfParentDocID,
  //             pdfType: pdfType,
  //             pdfPublicID: pdfPublicID,
  //             pdfURL: pdfURL,
  //           }
  //         )
  //
  //         this.setState({pdf: tempPDF});
  //
  //       });
  //
  //   }
  //
  // }

  export function getPDFWithId(pdfId){

    console.log(`PDF 9999999999999: ${pdfId}`);

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      DocumentPDFAPI.getPDFWithId(obj.jwt, pdfId).then((pdf) => {
        console.log(`PDF downloaded: ${JSON.stringify(pdf)}`);
        console.log(`PDF downloaded: ${pdf}`);
          //this.setState({documentPDF: pdf});
          //window.open(pdf)
          //return pdf.json()
      });
    }
  }


  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
