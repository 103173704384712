//api url

//local computer
//export const API = "http://localhost:5002";

//digital ocean webserver
export const API = "https://api.turbinerunnerapp.com";

//dont know what this is for
// export const API = "http://back.turbinerunner.com";

//dont think this is needed
//const api = "https://turbinerunnerapp.com:5002";

export const HEADERS = {
    "Accept": "application/json",
}