/**
 * This example shows how you can use custom nodes and edges to dynamically add elements to your react flow graph.
 * A global layouting function calculates the new positions for the nodes every time the graph changes and animates existing nodes to their new position.
 *
 * There are three ways of adding nodes to the graph:
 *  1. Click an existing node: Create a new child node of the clicked node
 *  2. Click on the plus icon of an existing edge: Create a node in between the connected nodes of the edge
 *  3. Click a placeholder node: Turn the placeholder into a "real" node to prevent jumping of the layout
 *
 * The graph elements are added via hook calls in the custom nodes and edges. The layout is calculated every time the graph changes (see hooks/useLayout.ts).
 **/

import React, { useState, useEffect } from 'react';
import ReactFlow, { useReactFlow, Background, Edge, Node, ProOptions, ReactFlowProvider, MarkerType } from 'reactflow';


import useLayout from './hooks/useLayout';
import nodeTypes from './NodeTypes';
import edgeTypes from './EdgeTypes';

import 'reactflow/dist/style.css';

//const ProOptions = { account: 'paid-pro', hideAttribution: true };

const fitViewOptions = {
  padding: 0.2,
  
};




function ReactFlowPro({myProps}) {

  console.log('Flow Props', myProps)
  console.log('Flow Props Selected Document', myProps.selectedDocument)
  console.log('allDocuments', myProps.documents)
  console.log('Flow Props Pages', myProps.pages)

  const defaultZoom = .2; // Default zoom level
  const defaultPosition = [300, 300]; // Default position

  
  let filteredPages = myProps.pages.filter(
    
    (page) => {

      console.log('page', page.pageParentDocID);

      if(page !== undefined && page.pageParentDocID === myProps.selectedDocument){
        return page;
      }
    }
  )

  let allDocuments = myProps.documents;
  console.log('allDocuments2', allDocuments)

  //function to create nodes *********************************************************
  function createDocumentNodes(documentID, allDocuments, allPages) {

    console.log('allDocuments3', allDocuments)
    // Find the selected document based on documentID
    const selectedDocument = allDocuments.find(doc => doc._id === documentID);
    if (!selectedDocument) {
      console.log('Document not found');
      return [];
    }
  
    // Find the first page of the selected document
    const firstPage = allPages.find(page => page._id === selectedDocument.firstPageID);
    if (!firstPage) {
      console.log('First page not found');
      return [];
    }
  
    // Filter pages that belong to the selected document
    const filteredPages = allPages.filter(page => page.pageParentDocID === documentID);
  
    // Create nodes array
    const nodes = filteredPages.map((page, index) => ({
      id: page._id,
      data: { label: page.pageTitle || 'Page - ' + page._id.substring(page._id.length - 5, page._id.length), myProps, content: JSON.stringify(page.pageTitle)/*onButtonClick: () => alert('Button clicked!'),*/ },
      position: { x: 0, y: 50 + index * 100 }, // Example positioning
      type: 'workflow', // Assuming you have a 'workflow' type in your nodeTypes
      content: page,
    }));
  
    console.log('newNodes', nodes)

    return nodes;
  }

  // Function to create edges
function createDocumentEdges(documentID, allPages) {
  
  // Filter pages that belong to the selected document
  const filteredPages = allPages.filter(page => page.pageParentDocID === documentID);
  
  // Create edges array
  //const edges = allPages.map((page, index) => {
    const edges = filteredPages.flatMap((page, index) => {
    // Assuming each page has a reference to its parent (e.g., pageParentPageID)
    // Skip if it's a root page without a parent
    if (!page.pageParentPageID) {
      return null;
    }

    return page.childPages.flatMap((childPage, index2) => {
      if(childPage){

        console.log(`Page ${page._id} childPage ${childPage} : ${index2} inside edges`)

        //q:  I want to find the child page in filtered pages in the next line
        //a:  I can use the find method to find the child page in the filtered pages
        //q: what is that method
        //q write the mothod
        const foundChildPage = filteredPages.find(page => page._id === childPage)

        if(foundChildPage.pageParentPageID === page._id){

          //not able to single out the links because I do not have the object of the child page only the ID of the childpage.. will have to get the object first
          return {
              id: `e${page._id}-${childPage}`, // Edge id format: 'e{parent_id}-{child_id}'
              source: page._id, // Parent page id
              target: childPage, // Current page id
              markerEnd: {
                type: MarkerType.Arrow,
              },
              type: 'smoothstep', // Assuming you have a 'smoothstep' type in your edgeTypes
              //style: { stroke: '#ff0000' }, // Set the edge color to red
            };

          }else{
            
            //not able to single out the links because I do not have the object of the child page only the ID of the childpage.. will have to get the object first
            console.log(`childPage.pageParentPageID: ${childPage} page._id ${page._id} : ${index2} inside edges return`)
            return {

              id: `e${page._id}-${childPage}`, // Edge id format: 'e{parent_id}-{child_id}'
              source: page._id, // Parent page id
              target: childPage, // Current page id
              markerEnd: {
                type: MarkerType.Arrow,
              },
              type: 'bezier', // Assuming you have a 'smoothstep' type in your edgeTypes
              style: { stroke: '#ff0000', strokeDasharray: '5,5', strokeWidth: 4 }, // Set the edge color to red
            };
          }
        
      }else{
        console.log(`undefined childpage`)
      }
      

      
    })

    /*return {
      id: `e${page.pageParentPageID}-${page._id}`, // Edge id format: 'e{parent_id}-{child_id}'
      source: page.pageParentPageID, // Parent page id
      target: page._id, // Current page id
      markerEnd: {
        type: MarkerType.Arrow,
      },
      type: 'smoothstep', // Assuming you have a 'smoothstep' type in your edgeTypes
    };*/

  });


  console.log('newEdges1', edges.filter(edge => edge !== null && edge.source !== "firstpage"));
  // Filter out null values in case of root pages
  return edges.filter(edge => edge !== null && edge.source !== "firstpage");

  //return edges.filter(edge => edge !== null);


}

  //createDocumentNodes(myProps.selectedDocument, allDocuments, filteredPages);

  // Use useEffect to update nodes when myProps change
  /*useEffect(() => {
    const newNodes = createDocumentNodes(myProps.selectedDocument, myProps.documents, filteredPages);
    setNodes(newNodes); // Update the nodes state
  }, [myProps.selectedDocument, myProps.documents, myProps.pages]); // Dependencies
*/
  

/*useEffect(() => {
    // Generate new nodes based on the selected document
    const newNodes = createDocumentNodes(myProps.selectedDocument, myProps.documents, filteredPages);
  
    // Generate new edges based on the pages
    const newEdges = createDocumentEdges(filteredPages);
  
    // Update the nodes and edges state
    setNodes(newNodes);
    setEdges(newEdges);
  }, [myProps.selectedDocument, myProps.documents, myProps.pages]); // Dependencies*/


  console.log('Flow Props Filtered Pages', filteredPages)


  const [nodes, setNodes] = useState([
    // ... initial nodes ...
    {
      id: '1',
      data: { label: '1🌮 Taco', onButtonClick: () => alert('Button clicked!') },
      position: { x: 0, y: 100 },
      type: 'workflow',
    },
    /*{
      id: '659e1895d632ec7c197c5549',
      data: { label: '2🌮 Taco' },
      position: { x: 0, y: 150 },
      type: 'workflow',
    },
    {
      id: '659e18bbd632ec7c197c5570',
      data: { label: '3🌮 Taco' },
      position: { x: 0, y: 150 },
      type: 'workflow',
    },
    {
      id: '659f7b99d632ec7c197c5c13',
      data: { label: '3🌮 Taco' },
      position: { x: 0, y: 150 },
      type: 'workflow',
    },*/


  ]);
  
  const [edges, setEdges] = useState([
    // ... initial edges ...
    /*{
      id: "e6432d63b28d4759927916c97-659e18bbd632ec7c197c5570",
      source: "6432d63b28d4759927916c97",
      target: "659e1895d632ec7c197c5549",
      type: 'smoothstep',
    },
    {
      id: "e6432d63b28d4759927916c97-659e18bbd632ec7c197c5570",
      source: "6432d63b28d4759927916c97",
      target: "659e18bbd632ec7c197c5570",
      type: 'smoothstep',
    },
    {
      id: "e6432d63b28d4759927916c97-659f7b99d632ec7c197c5c13",
      source: "6432d63b28d4759927916c97",
      target: "659f7b99d632ec7c197c5c13",
      type: 'smoothstep',
    },*/

  ]);

  useEffect(() => {
    console.log('useEffect Triggered')
    console.log('useEffect', myProps.selectedDocument, myProps.documents, filteredPages)

    console.log("Updated Nodes before state state:", nodes);
    console.log("Updated Edges before state:", edges);

    // Generate new nodes based on the selected document
    const newNodes = createDocumentNodes(myProps.selectedDocument, myProps.documents, filteredPages);
  
    // Generate new edges based on the pages
    const newEdges = createDocumentEdges(myProps.selectedDocument, filteredPages);

    console.log('useEffect New Nodes', nodes)
    console.log('useEffect New Edges', edges)
  
    // Update the nodes and edges state
    setNodes(newNodes);
    setEdges(newEdges);

    console.log("Updated Nodes after state:", newNodes);
    console.log("Updated Edges after state:", newEdges);
  }, [myProps.selectedDocument, myProps.documents, myProps.pages]); // Dependencies

  useLayout();

  const someData2 = myProps;
  //console.log('Default Zoom:', defaultZoom, 'Default Position:', defaultPosition);


  return (
    <>
      <ReactFlow 

        key={myProps.selectedDocument}
        myProps2={someData2} 
        defaultNodes={nodes}
        defaultEdges={edges}
        nodes={nodes}
        edges={edges}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        //fitViewOptions={fitViewOptions}
        minZoom={0.1}
        nodesDraggable={false}
        nodesConnectable={true}
        zoomOnDoubleClick={true}
        //defaultZoom={0.5}
        //defaultPosition={[defaultPosition]}
        //defaultViewport={100,100,0.2}
        //fitView
        
      >
        <Background />
      </ReactFlow>
    </>
  );
}

function ReactFlowWrapper(props) {

  console.log('Flow Props', props)
  const someData = props;
  console.log('some data', someData)

  return (
    
    <ReactFlowProvider >
      <ReactFlowPro myProps={someData} />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;


// original code
/*
import React from 'react';
import ReactFlow, { Background, Edge, Node, ProOptions, ReactFlowProvider } from 'reactflow';

import useLayout from './hooks/useLayout';
import nodeTypes from './NodeTypes';
import edgeTypes from './EdgeTypes';

import 'reactflow/dist/style.css';

const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };

// initial setup: one workflow node and a placeholder node
// placeholder nodes can be turned into a workflow node by clicking on them
const defaultNodes: Node[] = [
  {
    id: '1',
    data: { label: '🌮 Taco' },
    position: { x: 0, y: 0 },
    type: 'workflow',
  },
  {
    id: '2',
    data: { label: '+' },
    position: { x: 0, y: 150 },
    type: 'placeholder',
  },
];

// initial setup: connect the workflow node to the placeholder node with a placeholder edge
const defaultEdges: Edge[] = [
  {
    id: '1=>2',
    source: '1',
    target: '2',
    type: 'placeholder',
  },
];

const fitViewOptions = {
  padding: 0.95,
};

function ReactFlowPro() {
  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();

  return (
    <>
      <ReactFlow
        defaultNodes={defaultNodes}
        defaultEdges={defaultEdges}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={0.2}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
      >
        <Background />
      </ReactFlow>
    </>
  );
}

function ReactFlowWrapper() {
  return (
    <ReactFlowProvider>
      <ReactFlowPro />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;

*/

