/*import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import cx from 'classnames';

import styles from './NodeTypes.module.css';
import useNodeClickHandler from '../hooks/useNodeClick';

const WorkflowNode = ({ id, data }: NodeProps) => {
  // see the hook implementation for details of the click handler
  // calling onClick adds a child node to this node
  const onClick = useNodeClickHandler(id);

  return (
    <div onClick={onClick} className={cx(styles.node)} title="click to add a child node">
      {data.label}
      <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
      <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
    </div>
  );
};

export default memo(WorkflowNode);

*/

import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import cx from 'classnames';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';

import styles from './NodeTypes.module.css';
import useNodeClickHandler from '../hooks/useNodeClick';

import {InputGroup, Jumbotron, Button, Image, Table, ButtonToolbar, Form, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal, Card, Tab, Tabs, Accordion} from 'react-bootstrap';



const WorkflowNode = ({ id, data, myProps}) => {
  // see the hook implementation for details of the click handler
  // calling onClick adds a child node to this node
  const onClick = useNodeClickHandler(id);

  //print props
  //q: when pringing data.myProps.userFirstName, why is it undefined?
  //a: because it is not passed in as props, it is passed in as data 
  console.log('WorkflowNode Props Page myProps3333', data);
  console.log('WorkflowNode Props Page myProps9', data.content);
  //console.log('WorkflowNode Props Page myProps3', myProps3);

  return (
    <div /*onClick={onClick}*/ className={cx(styles.node)} title={data.label} /*title="click to add a child node"*/>
      {data.label}
      <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
      <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
      
      <br></br>
      <br></br>
      {console.log('WorkflowNode Props Page ID2', id)}
        <Link to={"/editPage/"+id}>
            <Button bsstyle="success"/*onClick={() => data.onButtonClick()}*/  /*onClick={() => alert('Button clicked!' + id)}*/>
                Edit Page
            </Button>
        </Link>
     
    </div>
  );
};

export default memo(WorkflowNode);

