//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import * as DocumentsAPI from '../utils/DocumentsAPI';
import * as PagesAPI from '../utils/PagesAPI';
import * as NodesAPI from '../utils/NodesAPI';
import {getFromStorage, setInStorage, removeItem} from '../utils/storage.js'


  export function newDocument(docTitle, docAlarmCodes){

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      DocumentsAPI.newDocument(obj.jwt,
        {
          title: docTitle,
          alarmCode: docAlarmCodes,

        }).then((doc) => {
          console.log(`new contact added: ${doc}`);

          var tempDocuments = this.state.documents;

          var tempPages = this.state.pages;

          var tempNodes = this.state.nodes;

          tempDocuments.push(
            {
              _id: doc._id,
              title: doc.title,
              alarmCode: doc.alarmCode
            }
          )

          PagesAPI.newPage(obj.jwt,
            {
              pageTitle: 'Doc First Page',
              pageParentDocID: doc._id,
              pageParentPageID: 'firstpage',
              pageSelection: 'firstpage',
              content: "",
              childPages: [],


            }).then((page) => {
              console.log(`new page added: ${page}`);

              tempPages.push(
                {
                  _id: page._id,
                  pageTitle: page.pageTitle,
                  pageParentDocID: page.pageParentDocID,
                  pageParentPageID: page.pageParentPageID,
                  pageSelection: page.pageSelection,
                  content: [],
                  childPages: [],
                }
              )

              DocumentsAPI.updateDocument(obj.jwt, doc._id,
              {

                firstPageID: page._id

              }).then((doc) => {
                console.log(`doc updated: ${JSON.stringify(doc)}`);

                tempDocuments.map((tempDoc) => {
                  if(tempDoc._id === doc._id){

                      tempDoc.firstPageID = page._id

                  }
                })

                this.setState({

                    documents: tempDocuments,
                    pages: tempPages

                  });

              });

            });



            //new node for the new document
            NodesAPI.newNode(obj.jwt,
              {

                nodeParentDocID: doc._id,
                nodeParentPageID: 'firstnode',
                nodeSelection: 'firstnode',

                nodeSourcePosition: 'right',
                nodeType: 'input',
                nodeLabel: doc.title,
                nodePositionX: '0',
                nodePositionY: '80',


              }).then((node) => {
                console.log(`new node added: ${node}`);

                tempNodes.push(
                  {

                    nodeParentDocID: node.nodeParentDocID,
                    nodeParentPageID: node.nodeParentPageID,
                    nodeSelection: node.nodeSelection,

                    id: node._id,
                    sourcePosition: node.nodeSourcePosition,
                    type: node.nodeType,
                    data: { label: node.nodeLabel},
                    position: { x: node.nodePositionX, y: node.nodePositionY }

                  }
                )

                DocumentsAPI.updateDocument(obj.jwt, doc._id,
                {

                  firstNodeID: node._id

                }).then((doc) => {
                  console.log(`document updated: ${JSON.stringify(doc)}`);

                  tempDocuments.map((tempDoc) => {
                    if(tempDoc._id === doc._id){

                        tempDoc.firstNodeID = node._id

                    }
                  })

                  this.setState({

                      documents: tempDocuments,
                      pages: tempPages,
                      nodes: tempNodes

                    });

                });

              });



        });
    }
  }

  export function editDocument(selectedDocument, docTitle, docAlarmCodes, docFirstPageID){

    var tempDocTitle
    var tempDocAlarmCodes
    var tempDocFirstPageID

    if(docTitle === ""){
      tempDocTitle = selectedDocument.title
    }else{
      tempDocTitle = docTitle
    }
    console.log(`tempDocTitle: ${tempDocTitle}`)

    if(docAlarmCodes === ""){
      tempDocAlarmCodes = selectedDocument.alarmCode
    }else{
      tempDocAlarmCodes = docAlarmCodes
    }

    console.log(`tempDocAlarmCodes: ${tempDocAlarmCodes}`)

    if(docFirstPageID === ""){
      tempDocFirstPageID = selectedDocument.firstPageID
    }else {
      tempDocFirstPageID = docFirstPageID
    }

    console.log(`tempDocFirstPageID: ${tempDocFirstPageID}`)

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      DocumentsAPI.updateDocument(obj.jwt, selectedDocument._id,
      {
        title: tempDocTitle,
        alarmCode: tempDocAlarmCodes,
        firstPageID: tempDocFirstPageID

      }).then((doc) => {
        console.log(`contact updated: ${JSON.stringify(doc)}`);

        var tempDocuments = this.state.documents;

        tempDocuments.map((tempDoc) => {
          if(tempDoc._id === selectedDocument._id){

              tempDoc.title = tempDocTitle
              tempDoc.alarmCode = tempDocAlarmCodes
              tempDoc.firstPageID = tempDocFirstPageID

          }
        })

        this.setState(
          {
            documents: tempDocuments,
            selectedDocument: doc
          });

          console.log(`Selected Document: ${JSON.stringify(this.state.selectedDocument)}`);
      });

    }

  }

  export function viewDocument(documentID){

      var tempDocuments = this.state.documents;

      tempDocuments.map((doc) => {
        if(documentID === doc._id){
          {
            this.setState(
              {
                selectedDocument: doc
              });
          }
        }
      });

      console.log(`Selected document: ${JSON.stringify(this.state.selectedDocument)}`);
  }

  export function deleteDocument(documentID){

    const obj= getFromStorage('the_main_app')

    if (obj && obj.sessionID){

      DocumentsAPI.deleteDocument(obj.jwt, documentID).then((response) => {

        if(response.status === 200){
          console.log(`contact deleted`);

          var tempDocuments = this.state.documents;

          var filteredDocuments = tempDocuments.filter((doc) => {
            if(doc._id !== documentID){
              return doc;
            }
          });
        }

        this.setState({documents: filteredDocuments});
      });

      PagesAPI.deleteManyPages(obj.jwt, documentID).then((response) => {

        if(response.status === 200){
          console.log(`contact deleted`);

          var tempPages = this.state.pages;

          var filteredPages = tempPages.filter((page) => {
            if(page.pageParentDocID !== documentID){
              return page;
            }
          });
        }

        this.setState({pages: filteredPages});
      });

    }

  }

  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
