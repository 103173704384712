//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6

// import {getFromStorage, setInStorage} from './storage.js'
// import {fakeAuth} from './Authenticate_t.js'
import * as Const from './Constants'

/*********************** Content ***************************/

export const getPDF = (jwt) =>
  fetch(`${Const.API}/pdf`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const getPDFWithId = (jwt, id) =>
  fetch(`${Const.API}/pdf/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }

      console.log(`R: ${response}`)
      //window.open(response.pdf)
      //return response;

      //return response
    }
  )


export const newPDF = (jwt, pdf) =>
  fetch(`${Const.API}/pdf`, {
    method: "POST",
    body: JSON.stringify(pdf),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )


export const updatePDF = (jwt, id, pdf) =>
  fetch(`${Const.API}/pdf/${id}`, {
    method: "PUT",
    body: JSON.stringify(pdf),
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const deletePDF = (jwt, id) =>
  fetch(`${Const.API}/pdf/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )

export const deleteManyPDF = (jwt, id) =>
  fetch(`${Const.API}/pdf/${id}`,
  {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )

  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
