//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, { useState, useEffect, useRef } from 'react';
import {
  InputGroup, Jumbotron, Button, Image, Table, ButtonToolbar, Form, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal, Card, Tab, Tabs, Accordion
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useQuill } from 'react-quilljs';
//import 'quill/dist/quill.snow.css'; // include styles


function ViewDocument(props){
  
  const [state, setState] = useState({
    query: '',
    eventFirstName: '',
    eventDocumentTitle: '',
    eventDocumentAlarmCodes: '',
    eventFirstPageID: '',
    show: false,
    selectedDocument: '',

    eventContentType: '',
    eventContentOrder: '',

    eventCommentPost: '',
    eventCommentReply: '',

    eventChildComment: {},
    eventChildPageTitle: '',
    eventChildPageSelectionText: '',

    eventPageTitle: '',
    eventPageParentDocID: '',
    eventPageParentPageID: '',
    eventPageSelectionText: '',

    currentTime: '',

    pdfId: '5e263003a7a2049a1a5c8545',
  });

  /*const handleContentOrderChange = (event) => {
    this.setState({eventContentOrder: event.target.value})
    console.log('onType', this.state.eventContentOrder)
  }*/



  const handleContentOrderChange = (event) => {
    setState({ ...state, eventContentOrder: event.target.value });
  };

  const handleCommentPostChange = (event) => {
    setState({ ...state, eventCommentPost: event.target.value });
  };


  /*const handleCommentPostChange = (event) => {
    this.setState({eventCommentPost: event.target.value})
    console.log('onType', this.state.eventCommentPost)
  }*/

  /*const handleChildCommentChange = (input) => (event) => {
    console.log(`input: ******${input}`)

    var eventChildComment = this.state.eventChildComment
    var input2 = input
    eventChildComment[input2] = {}

    eventChildComment[input2] =  event.target.value
    this.setState({eventChildComment: eventChildComment})
    console.log('onType', this.state.eventChildComment[input])
  }*/

  const handleChildCommentChange = (input) => (event) => {
    var eventChildComment = { ...state.eventChildComment, [input]: event.target.value };
    setState({ ...state, eventChildComment: eventChildComment });
  };

  const handleCommentPostSubmit = (userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost) => {
    props.newComment(userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);
    setState({ ...state, eventCommentPost: "" });
  };

  /*const handleCommentPostSubmit = (userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost) => {
    this.props.newComment(userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);
    this.setState({eventCommentPost: ""});
    console.log('submit comments', this.state.eventChildComment)
  }*/

  const handleChildCommentSubmit = (comment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost, childComment) => {
    props.newChildComment(comment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);

    var newChildComment = { ...state.eventChildComment, [childComment]: "" };
    setState({ ...state, eventChildComment: newChildComment });
  };
  
  /*
  const handleChildCommentSubmit = (comment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost, childComment) => {
    this.props.newChildComment(comment, userOrganization, userTeam, userFirstName, selectedDocument, selectedPage, eventCommentPost);

    var newChildComment = this.state.eventChildComment
    newChildComment[childComment] = ""

    this.setState({eventChildComment: newChildComment})
  }*/

  const getPDFWithId1 = (pdfId) => {
    return (
      <div className="App">
        <a href={props.getPDFWithId(pdfId)} target="_blank" rel="noopener noreferrer">Download Pdf</a>
      </div>
    );
  };
 
 
  /*const getPDFWithId1 = (pdfId) => {
    return (
      <div className = "App">
        <a href = {this.props.getPDFWithId(pdfId)} target = "_blank">Download Pdf</a>
      </div>
    );
  }*/
  //<Button onClick={() => this.props.getPDFWithId(this.state.pdfId)} bsstyle="success">Test PDF</Button>

  //let filteredComments = this.props.comments
  const CheckContent = ({ content, index }) => {
    if (content !== undefined && content !== "") {
      if (content.contentType === "text") {
        return (
          <Row key={index} className="Testing5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>{content.contentText}</p>
            </Col>
          </Row>
        );
      } else if (content.contentType === "image") {
        return (
          <Row key={index} className="Testing5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Image src={content.contentImageURL} fluid />
              <p>{content.contentText}</p>
            </Col>
          </Row>
        );
      } else if (content.contentType === "video") {
        return (
          <Row key={index} className="Testing5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <video src={content.contentVideoURL} controls fluid />
            </Col>
          </Row>
        );
      }
    }
    return null; // Return null if no content or content type doesn't match
  };


const createMarkup = (htmlContent) => {
  
  return { __html: htmlContent };
};

//const quillRef = useRef(null);

const {quill, quillRef} = useQuill();


    console.log(`marketlistedit props ${props}`)
    console.log('Props', props)

    if(props.isLoading && props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button bsstyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else{
      if(props.isLoading  || props.sessionIsLoading){
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>TurbineRunner is Loading</h1>
            </Jumbotron>
          </div>
        )
      }
    }

    console.log('Props', props)

    return(
      <div className="viewPage">
        
        <Container>
        {props.selectedPage.pageParentPageID === undefined || props.selectedPage.pageParentPageID === "firstpage"
            ?
              <div className="text-left">
              <br/>
                <LinkContainer to="/viewdocumentlist">
                  <Button bsstyle="success">Back to Document List</Button>
                </LinkContainer>
                <br/>
                <br/>
              </div>
            :
              <div className="text-left">
                  <br/>
                  <Button onClick={() => props.viewPage(props.selectedPage.pageParentPageID)} bsstyle="success">Parent Page</Button>
                  <br/>
                  <br/>
              </div>
        }
        <Row  className="Testing2">
          {props.selectedPage.pageParentPageID === undefined || props.selectedPage.pageParentPageID === "firstpage"
              ?
                <br/>
              :
                <div>
                    <p><b>You selected: </b>{props.selectedPage.pageSelection}</p>
                </div>
          }
        </Row>
        {props.documents.filter(doc => doc._id === props.selectedPage.pageParentDocID).map((doc, iA) => (
          <div key={iA}>
            {props.pages.filter(page => page._id === props.selectedPage._id).map((page, iB) => (
              <div key={iB}>
                  <Row className="Testing8 Hero-banner" >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h2>{doc.title}</h2>
                      <p>Document ID: {doc._id.substring(doc._id.length - 5, doc._id.length)}</p>
                      <p>Page ID: {page._id.substring(page._id.length -5, page._id.length)}</p>
                      {/*<p>Turbine Model: {doc.turbineModel}</p>*/}
                      {/*<Button onClick={() => this.getPDFWithId1(this.state.pdfId)} bsstyle="success">Test PDF</Button>*/}
                      {doc.documentPDF !== "" ||  doc.documentPDF !== undefined
                          ?
                            <p  ></p>
                          :
                            <a className="Testing8" target="_blank" href={doc.documentPDF}>Document PDF</a>
                      }
                      <br/>
                      <br/>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="viewPage" xs={12} sm={12} md={12} lg={12} >
                    <div /*style={{textAlign: 'initial'}}*/>
                      {/*<div ref={quillRef} />*/}
                      <br/>
                      <br/>
                      <div dangerouslySetInnerHTML={createMarkup(page.content)} />
                      <br/>
                      <br/>
                    </div>
                    </Col>
                  </Row>
                  <Row className="Testing8"  >
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h4>Make a selection below:</h4>
                    </Col>
                  </Row>
                  <Row className="Testing2"  >
                    <Col xs={1} sm={1} md={1} lg={1}>
                      <h6>#</h6>
                    </Col>
                    <Col xs={8} sm={9} md={9} lg={9}>
                      <h6 className="text-left">Page Selection</h6>
                    </Col>
                    <Col xs={3} sm={2} md={2} lg={2}>
                      <h6>Action</h6>
                    </Col>
                  </Row>
                  {console.log(props.selectedPage.childPages.length)}
                  {props.selectedPage.childPages !== undefined && props.selectedPage.childPages.length !== 0 && props.selectedPage.childPages.length > 1 
                      ?
                        props.selectedPage.childPages.map((value, ia) => (
                          props.pages.filter(page => page._id !== 0 && page._id === value).map((page, ib) => (
                            <Row key={ia} className="Testing">
                              <Col xs={1} sm={1} md={1} lg={1}>
                                {ia + 1}
                              </Col>
                              <Col xs={8} sm={9} md={9} lg={9}>
                              <p className="text-left">{page.pageSelection}</p>
                              </Col>
                              <Col xs={3} sm={2} md={2} lg={2}>
                                  <Button onClick={() => props.viewPage(page._id)} bsstyle="success">Select</Button>
                               </Col>
            
                            </Row>
                          )
                        )))
                      :
                        
                        /**************************************************************************/

                        props.selectedPage.childPages.length === 1

                        ?
                        
                        props.selectedPage.childPages.map((value, ia) => (
                          props.pages.filter(page => page._id !== 0 && page._id === value).map((page, ib) => (
                            <Row key={ia} className="Testing">
                              <Col xs={1} sm={1} md={1} lg={1}>
                                {ia + 1}
                              </Col>
                              <Col xs={8} sm={9} md={9} lg={9}>
                              <p className="text-left">{page.pageSelection}</p>
                              </Col>
                              <Col xs={3} sm={2} md={2} lg={2}>
                                  <Button onClick={() => props.viewPage(page._id)} bsstyle="success">Next</Button>
                              </Col>
                            </Row>
                          )
                        )))
                        
                        :

                        <Row className="Testing">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <p>Nothing to select</p>
                          </Col>
                        </Row>
                      }
              </div>
            ))}
          </div>
        ))}
        <br/>
        <br/>
        <br/>
        <Tabs defaultActiveKey="organization" id="uncontrolled-tab-example">
          <Tab eventKey="organization" title={props.userOrganization}>
            <br/>
            <form >
              <Form.Group controlId="formControlsTextarea">
                <Form.Label>Post Comment</Form.Label>
                <Form.Control onChange={handleCommentPostChange} as="textarea" value={state.eventCommentPost} placeholder="Enter a comment about this document" rows="3" />
              </Form.Group>
              <Button onClick={() => handleCommentPostSubmit(props.userOrganization, props.userTeam, props.userFirstName, props.selectedDocument, props.selectedPage._id, this.state.eventCommentPost)} bsstyle="success">Submit New Comment</Button>
            </form>
            <br/>
            <br/>
            {props.selectedPage.pageParentPageID === "firstpage"
              ?
                  props.comments.filter(comment => comment.commentParentDocID === props.selectedDocument && comment.commentParentCommentID === "").map((comment, iA) => (
                    <div key={`${comment._id}` + `${iA}`}>
                    <Row className="Testing" >
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <h2 className="text-left">{comment.commentUser + ": " + comment.commentText}</h2>
                        <ul>
                          <li list-style-type="none" className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</li>
                          <li className="text-left">Comment ID: {comment._id.substring(comment._id.length -5, comment._id.length)}</li>
                          <li className="text-left">Page ID:{<Button variant="link" onClick={() => props.viewPage(comment.commentParentPageID)} bsstyle="success">{comment.commentParentPageID.substring(comment.commentParentPageID.length - 5, comment.commentParentPageID.length)}</Button>}</li>
                       </ul>
                      </Col>
                    </Row>
                { comment.childComments !== undefined && comment.childComments.length !== 0
                  ?
                      comment.childComments.map((value, ia) => (
                        props.comments.filter(comment1 => comment1._id === value).map((comment2, ib) => (
                          <Row key={`${comment2._id} + ${ib}`} className="Testing">
                            <Col xs={1} sm={1} md={1} lg={1}>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={11}>
                            <h2 className="text-left">{comment2.commentUser + ": " + comment2.commentText}</h2>
                            <ul>
                              <li className="text-left">{comment2.commentOrganization + ": " + comment2.commentTeam}</li>
                              <li className="text-left">Comment ID: {comment2._id.substring(comment2._id.length - 5, comment2._id.length)}</li>
                              <li className="text-left">Parent Comment ID: {comment2.commentParentCommentID.substring(comment2.commentParentCommentID.length - 5, comment2.commentParentCommentID.length)}</li>
                            </ul>
                            </Col>
                          </Row>
                        )
                      )))
                    :
                      <Row key={comment._id} className="Testing">
                          <Col xs={12} sm={12} md={12} lg={12}>
                              <p>Currently no replies to {comment.commentUser}'s comment</p>
                          </Col>
                        </Row>
                    }
                    <Row key={`${comment._id}` + `${iA}`} >
                      <Col xs={12} sm={12} md={12} lg={12}>
                          <Accordion>
                            <Card>
                              <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                  Click here to reply
                                </Accordion.Toggle>
                              </Card.Header>
                              <Accordion.Collapse eventKey="0">
                                <form key={`${comment._id}` + `${iA}`}>
                                  <Form.Group controlId={`${comment._id}` + `${iA}`}>
                                    <Form.Control onChange={handleChildCommentChange(`${comment._id}` + `${iA}`)} as="textarea" value={state.eventChildComment[`${comment._id}` + `${iA}`]} placeholder="Enter a reply" rows="3" />
                                  </Form.Group>
                                  <Button onClick={() => handleChildCommentSubmit(comment, this.props.userOrganization, props.userTeam, props.userFirstName, props.selectedDocument, props.selectedPage._id, state.eventChildComment[`${comment._id}` + `${iA}`], `${comment._id}` + `${iA}`)} bsstyle="success">Submit Reply</Button>
                                </form>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion>
                      </Col>
                    </Row>
                  </div>
                ))
              :
                props.comments.filter(comment => comment.commentParentPageID === props.selectedPage._id && comment.commentParentCommentID === "").map((comment, iA) => (
                  <div key={`${comment._id}` + `${iA}`}>
                    <Row className="Testing" key={iA}>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <h2 className="text-left">{comment.commentUser + ": " + comment.commentText}</h2>
                        <ul>
                          <li list-style-type="none" className="text-left">{comment.commentOrganization + ": " + comment.commentTeam}</li>
                          <li className="text-left">Comment ID: {comment._id.substring(comment._id.length - 5, comment._id.length)}</li>
                          <li className="text-left">Page ID: {comment.commentParentPageID.substring(comment.commentParentPageID.length - 5, comment.commentParentPageID.length)}</li>
                       </ul>
                      </Col>
                      </Row>
                        {comment.childComments !== undefined && comment.childComments.length !== 0
                          ?
                              comment.childComments.map((value, ia) => (
                              props.comments.filter(comment1 => comment1._id === value).map((comment2, ib) => (
                                <Row key={`${comment2._id} + ${ib}`} className="Testing">
                                  <Col xs={1} sm={1} md={1} lg={1}>
                                  </Col>
                                  <Col xs={11} sm={11} md={11} lg={11}>
                                  <h2 className="text-left">{comment2.commentUser + ": " + comment2.commentText}</h2>
                                  <ul>
                                    <li className="text-left">{comment2.commentOrganization + ": " + comment2.commentTeam}</li>
                                    <li className="text-left">Comment ID: {comment2._id.substring(comment2._id.length - 5, comment2._id.length)}</li>
                                    <li className="text-left">Parent Comment ID: {comment2.commentParentCommentID.substring(comment2.commentParentCommentID.length - 5, comment2.commentParentCommentID.length)}</li>
                                  </ul>
                                  </Col>
                                </Row>
                              )
                            )))
                          :
                          <Row key={comment._id} className="Testing">
                              <Col xs={12} sm={12} md={12} lg={12}>
                                  <p>Currently no replies to {comment.commentUser}'s comment</p>
                              </Col>
                            </Row>
                      }
                      <Row key={`${comment._id}` + `${iA}`} >
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Accordion>
                              <Card>
                                <Card.Header>
                                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Click here to reply
                                  </Accordion.Toggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                  <form key={`${comment._id}` + `${iA}`}>
                                    <Form.Group controlId={`${comment._id}` + `${iA}`}>
                                      <Form.Control onChange={handleChildCommentChange(`${comment._id}` + `${iA}`)} as="textarea" value={this.state.eventChildComment[`${comment._id}` + `${iA}`]} placeholder="Enter a reply" rows="3" />
                                    </Form.Group>
                                    <Button onClick={() => handleChildCommentSubmit(comment, props.userOrganization, props.userTeam, props.userFirstName, props.selectedDocument, props.selectedPage._id, this.state.eventChildComment[`${comment._id}` + `${iA}`], `${comment._id}` + `${iA}`)} bsstyle="success">Submit Reply</Button>
                                  </form>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                        </Col>
                      </Row>
                </div>
              ))
            }
          </Tab>
          <Tab eventKey="public" title="Public">
          </Tab>
        </Tabs>
        </Container>
      </div>
    )
  
}
export default ViewDocument;
