//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6
import * as ContactsAPI from '../utils/ContactsAPI';
import * as DocumentsAPI from '../utils/DocumentsAPI';
import * as PagesAPI from '../utils/PagesAPI';
import * as CommentsAPI from '../utils/CommentsAPI';



  export function newComment(commentOrganization, commentTeam, commentUser, commentParentDocID, commentParentPageID, commentText){

    console.log("function commentOrganization " + commentOrganization);
    console.log("function commentTeam " + commentTeam);
    console.log("function commentUser " + commentUser);
    console.log("function commentParentDocID " + commentParentDocID);
    console.log("function commentParentPageID " + commentParentPageID);
    console.log("function commentText " + commentText);


    CommentsAPI.newComment(
      {
        commentOrganization: commentOrganization,
        commentTeam: commentTeam,
        commentUser: commentUser,
        //commentParentCommentID: "",
        commentParentDocID: commentParentDocID,
        commentParentPageID: commentParentPageID,
        commentText: commentText,
        childComments: [],

      }).then((comment) => {
        console.log(`new comment added: ${JSON.stringify(comment)}`);

        var tempComments = this.state.comments;

        tempComments.unshift(
          {
            _id: comment._id,
            commentOrganization: comment.commentOrganization,
            commentTeam: comment.commentTeam,
            commentUser: comment.commentUser,
            commentParentCommentID: comment.commentParentCommentID,
            commentParentDocID: comment.commentParentDocID,
            commentParentPageID: comment.commentParentPageID,
            commentText: comment.commentText,
            childComments: comment.childComments,
          }
        )

        this.setState({comments: tempComments});

      });

  }

  export function newChildComment(parentComment, commentOrganization, commentTeam, commentUser, commentParentDocID, commentParentPageID, commentText){

    console.log(`Parent comment: ${JSON.stringify(parentComment)}`)
    console.log(`Parent comment2: ${parentComment._id}`)

    CommentsAPI.newComment(
      {
        commentOrganization: commentOrganization,
        commentTeam: commentTeam,
        commentUser: commentUser,
        commentParentCommentID: parentComment._id,
        commentParentDocID: commentParentDocID,
        commentParentPageID: commentParentPageID,
        commentText: commentText,
        childComments: [],

      }).then((returnedComment) => {
        console.log(`returned comment: ${JSON.stringify(returnedComment)}`);

        var tempComments = this.state.comments;

        //var tempSelectedPage;

        tempComments.push(
          {
            _id: returnedComment._id,
            commentOrganization: returnedComment.commentOrganization,
            commentTeam: returnedComment.commentTeam,
            commentUser: returnedComment.commentUser,
            commentParentCommentID: returnedComment.commentParentCommentID,
            commentParentDocID: returnedComment.commentParentDocID,
            commentParentPageID: returnedComment.commentParentPageID,
            commentText: returnedComment.commentText,
            childComments: returnedComment.childComments,
          }
        )

        //the new child page has been created, but the parent page does not know anything about it
        //need to modify the parent page in memory then
        //need to update the parent pag ei the database then in the UI

        tempComments.map((comment) => {
          if(comment._id !== undefined  && comment._id === parentComment._id){

              comment.childComments.push(
                returnedComment._id
              )

              console.log(`Parent Comment ******: ${JSON.stringify(parentComment)}`);
              //now send to the update parent page to the database

              CommentsAPI.updateComment(parentComment._id,
              {

                childComments: comment.childComments,

              }).then((returnedParentComment) => {
                console.log(`returned Parent Page **************: ${JSON.stringify(returnedParentComment)}`);

                //tempSelectedComment = returnedParentPage

                this.setState(
                  {
                    comments: tempComments,
                    //selectedComment: tempSelectedComment,

                  });

              });
          }
        })
      });

  }

  export function editComment(selectedPage, pageTitle, pageParentDocID, pageParentPageID, pageSelectionText){

    var tempPageTitle
    var tempPageParentDocID
    var tempPageParentPageID
    var tempPageSelectionText

    if(pageTitle === ""){
      tempPageTitle = selectedPage.pageTitle
    }else{
      tempPageTitle = pageTitle
    }

    if(pageParentDocID === ""){
      tempPageParentDocID = selectedPage.pageParentDocID
    }else{
      tempPageParentDocID = pageParentDocID
    }

    if(pageParentPageID === ""){
      tempPageParentPageID = selectedPage.pageParentPageID
    }else{
      tempPageParentPageID = pageParentPageID
    }

    if(pageSelectionText === ""){
      tempPageSelectionText = selectedPage.pageSelection
    }else{
      tempPageSelectionText = pageSelectionText
    }

    PagesAPI.updatePage(selectedPage._id,
    {
      pageTitle: tempPageTitle,
      pageParentDocID: tempPageParentDocID,
      pageParentPageID: tempPageParentPageID,
      pageSelection: tempPageSelectionText,


    }).then((returnedPage) => {
      console.log(`contact updated: ${JSON.stringify(returnedPage)}`);

      var tempPages = this.state.pages;

      var selectedPage = returnedPage;

      tempPages.map((page) => {
        if(selectedPage._id === page._id){

            page.pageTitle = returnedPage.pageTitle;
            page.pageParentDocID = returnedPage.pageParentDocID;
            page.pageParentPageID = returnedPage.pageParentPageID;
            page.pageSelection = returnedPage.pageSelection;
        }
      })

      this.setState(
        {
          pages: tempPages,
          selectedPage: returnedPage
        });

    });


  }

  export function viewComment(pageID){

      var tempPages = this.state.pages;

      tempPages.map((page) => {
        if(pageID === page._id){
          {
            this.setState(
              {
                selectedPage: page,
                selectedDocument: page.pageParentDocID
              });
          }
        }
      });

      console.log(`Selected page: ${JSON.stringify(this.state.selectedPage)}`);
  }



  export function deleteComment(pageID){

    PagesAPI.deletePage(pageID).then((response) => {

      if(response.status === 200){
        console.log(`contact deleted`);

        var tempPages = this.state.pages;


        var filteredPages = tempPages.filter((page) => {
          if(page._id !== pageID){
            return page;
          }
        });

        this.setState({pages: filteredPages});

        filteredPages = filteredPages.map((page) => {

          page.childPages = page.childPages.filter((childPage) => {
            if(childPage !== pageID){
              return childPage
            }
          })


          console.log(`right before update page: ${page._id}`);

          PagesAPI.updatePage(page._id,
          {


            childPages: page.childPages,

          }).then((returnedPage) => {
            console.log(`returned page *************: ${returnedPage}`);

            this.setState({pages: filteredPages});

          });

          return page

        });

      }

    });

  }


  export function deleteManyComments(docID){

    PagesAPI.deleteManyPages(docID).then((response) => {

      if(response.status === 200){
        console.log(`pages deleted`);

        var tempPages = this.state.pages;

        var filteredPages = tempPages.filter((page) => {
          if(page.pageParentDocID !== docID){
            return page;
          }
        });


      }

      this.setState({pages: filteredPages});
    });

  }







  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
