//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class EditDocumentAdmin extends Component{
  state = {
    query: '',
    eventFirstName: '',
    eventDocumentTitle: '',
    eventDocumentAlarmCodes: '',
    eventDocumentFirstPageID: '',
    show: false,
    selectedDocument: '',
    selectedPage: '',

    eventPageTitle: '',
    eventPageSelectionText: '',
    eventPageParentPageID: '',
  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){
      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  handleShow = (page) => {
    this.setState(
      {
        show: true,
        selectedPage: page,
      });
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleCloseAndDelete = (pageID) => {

    this.setState({show: false});
    this.props.deletePage(pageID)
  }

  handleFirstNameChange = (event) => {

    this.setState({eventFirstName: event.target.value})
    console.log('onType', this.state.eventFirstName)
  }


  handleDocumentTitleChange = (event) => {

    this.setState({eventDocumentTitle: event.target.value})
    console.log('onType', this.state.eventDocumentTitle)
  }

  handleDocumentAlarmCodes = (event) => {

    this.setState({eventDocumentAlarmCodes: event.target.value})
    console.log('onType', this.state.eventDocumentAlarmCodes)
  }

  handleDocumentFirstPageID = (event) => {

    this.setState({eventDocumentFirstPageID: event.target.value})
    console.log('onType', this.state.eventDocumentFirstPageID)
  }

  handlePageTitleChange = (event) => {

    this.setState({eventPageTitle: event.target.value})
    console.log('onType', this.state.eventPageTitle)
  }

  handlePageSelectionTextChange = (event) => {

    this.setState({eventPageSelectionText: event.target.value})
    console.log('onType', this.state.eventPageSelectionText)
  }


  handlePageParentPageIDChange = (event) => {

    this.setState({eventPageParentPageID: event.target.value})
    console.log('onType', this.state.eventPageParentPageID)
  }


  render(){
    console.log(`marketlistedit props ${this.props}`)
    console.log('Props', this.props)


    if(this.props.isLoading && this.props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else{

      if(this.props.isLoading  || this.props.sessionIsLoading){
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>CW-Proj is Loading</h1>
            </Jumbotron>
          </div>
        )
      }
    }

    /*if(!this.props.isAuthenticated){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>you are not logged in go back</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">login</Button>
          </LinkContainer>
        </div>
      )
    }*/
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredPages = this.props.pages.filter(
      (page) => {

        if(page !== undefined){
          return page.pageTitle.toLowerCase().indexOf(this.state.query) !== -1
        }

      }
    )

    return(

      <div className="viewPage">

        <Container>
        <br/>
        <h1>Document: {this.props.selectedDocument.title}</h1>
        <p>Document ID: {this.props.selectedDocument._id}</p>
        <p>Turbine Model: {this.props.selectedDocument.turbineModel}</p>
        <p>Alarm Codes: {this.props.selectedDocument.alarmCode}</p>
        <p>Document Tools: {this.props.selectedDocument.tools}</p>
        <p>First Page ID: {this.props.selectedDocument.firstPageID}</p>
        <br/>


        <form >
          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Document Title</FormLabel>
              <FormControl onChange={this.handleDocumentTitleChange} defaultValue={this.props.selectedDocument.title} placeholder="Enter a Document Title" style={{ height: 30 }}/><br/>
              <FormLabel>Alarm Codes</FormLabel>
              <FormControl onChange={this.handleDocumentAlarmCodes}  defaultValue={this.props.selectedDocument.alarmCode} placeholder="Enter Alarm Codes" style={{ height: 30 }}/><br/>
              <FormLabel>First Page ID</FormLabel>
              <FormControl onChange={this.handleDocumentFirstPageID}  defaultValue={this.props.selectedDocument.firstPageID} placeholder="Enter First Page ID" style={{ height: 30 }}/><br/>
              <Button onClick={() => this.props.editDocument(this.props.selectedDocument, this.state.eventDocumentTitle, this.state.eventDocumentAlarmCodes, this.state.eventDocumentFirstPageID)} bsstyle="success">Update Document</Button><br/><br/>
          </FormGroup>

        </form>

        <br/>
        <h1>{this.props.selectedDocument.title} Pages</h1>
        <br/>

        <form >

          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Page Title</FormLabel>
              <FormControl onChange={this.handlePageTitleChange} placeholder="Enter a Page Title" style={{ height: 30 }}/><br/>
              <FormLabel>Page Parent Page ID</FormLabel>
              <FormControl onChange={this.handlePageParentPageIDChange} placeholder="Enter a Page Title" style={{ height: 30 }}/><br/>
              <FormLabel>Page Selection Text</FormLabel>
              <FormControl onChange={this.handlePageSelectionTextChange} placeholder="Enter Page selection text" style={{ height: 30 }}/><br/>
              <Button onClick={() => this.props.newPage(this.state.eventPageTitle, this.props.selectedDocument._id, this.state.eventPageParentPageID, this.state.eventPageSelectionText)} bsstyle="success">New Page</Button><br/><br/>
          </FormGroup>

        </form>


        {/*

        <form >
          <FormGroup  controlId="formControlsTextarea">
              <FormLabel>Page Name</FormLabel>
              <FormControl onChange={this.handleDeliverableNameChange}  placeholder="textarea" style={{ height: 30 }}/>

          </FormGroup>
          <Button onClick={() => this.props.newPage(this.props.selectedMarket._id,this.state.eventDeliverableName)} bsstyle="success">Submit New Deliverable</Button>
        </form>
        */}

        <br/>
        <Row className="Testing2"  >
          <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="formControlsTextarea2">
                  <FormLabel>Search</FormLabel>

                  <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Page Title" />

              </FormGroup>
            </Col>
          </Row>


              <Row className="Testing2">
                <Col xs={1} sm={1} md={1} lg={1}>
                  <h6>#</h6>
                </Col>
                <Col xs={3} sm={1} md={1} lg={1}>
                  <h6>Page ID</h6>
                </Col>
                <Col className="d-none d-sm-block" sm={2} md={2} lg={2}>
                  <h6>Parent Doc ID</h6>
                </Col>
                <Col className="d-none d-sm-block" sm={1} md={1} lg={1}>
                  <h6>Parent Page ID</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <h6>Page Selection</h6>
                </Col>
                <Col xs={2} sm={1} md={1} lg={1}>
                  <h6>First Page?</h6>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <h6>Action</h6>
                </Col>
              </Row>


              {filteredPages.filter(page => page._id !== 0 && page.pageParentDocID === this.props.selectedDocument._id  && page.pageParentDocID !== undefined).map((page, i) => (

                <Row className="Testing" key={i}>
                  <Col xs={1} sm={1} md={1} lg={1}>
                    <p>{i + 1}</p>
                  </Col>
                  <Col xs={3} sm={1} md={1} lg={1}>
                    {/* <h6>{page._id}</h6> */}
                    <p>{page._id !== undefined ? page._id.substring(page._id.length - 5, page._id.length) : "No Page"}</p>
                  </Col>
                  <Col className="d-none d-sm-block" sm={2} md={2} lg={2}>
                    {/*  <h6>{page.pageParentDocID}</h6>  */}
                    <p>{page.pageParentDocID !== undefined ? page.pageParentDocID.substring(page.pageParentDocID.length - 5, page.pageParentDocID.length) : "No Parent Document ID"}</p>
                  </Col>
                  <Col className="d-none d-sm-block" sm={1} md={1} lg={1}>
                    <p>{page.pageParentPageID !== undefined ? page.pageParentPageID.substring(page.pageParentPageID.length - 5, page.pageParentPageID.length) : "No Parent Page ID"}</p>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                    <p>{page.pageSelection}</p>
                  </Col>
                  <Col xs={2} sm={1} md={1} lg={1}>
                    <p>{page._id === this.props.selectedDocument.firstPageID  ? "Yes" : "No"}</p>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3}>
                  <LinkContainer to="/editpageadmin">
                    <Button onClick={() => this.props.viewPage(page._id)} bsstyle="success">Edit</Button>
                  </LinkContainer>
                  <LinkContainer to="/viewdocument">
                    <Button onClick={() => this.props.viewPage(page._id)} bsstyle="success">View</Button>
                  </LinkContainer>
                    <Button variant="danger" onClick={() => this.handleShow(page)}>
                      Delete
                    </Button>
                  </Col>

                  <Modal show={this.state.show} onHide={this.handleClose}>
                   <Modal.Header closeButton>
                     <Modal.Title>Delete Page</Modal.Title>
                   </Modal.Header>
                   <Modal.Body>Warning!!! Are you sure you want to delete the page "{this.state.selectedPage.pageTitle}"?</Modal.Body>
                   <Modal.Footer>
                     <Button variant="secondary" onClick={this.handleClose}>
                       Close
                     </Button>
                     <Button variant="danger" onClick={() => this.handleCloseAndDelete(this.state.selectedPage._id)}>
                       Delete
                     </Button>
                   </Modal.Footer>
                 </Modal>

                </Row>

                ))}

            </Container>

      </div>


    )
  }
}
export default EditDocumentAdmin;
