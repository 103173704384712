//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal} from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class ViewDocumentList extends Component{
  state = {
    query: '',
    eventDocumentTitle: '',
    eventAlarmCodes: '',
    show: false,
    selectedDocument: '',
  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){

      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  //show = useState(false);

  //handleClose = () => setShow(false);
  //handleShow = () => setShow(true);

  handleShow = (docId) => {
    this.setState(
      {
        show: true,
        selectedDocument: docId,
      });
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleCloseAndDelete = (docID) => {

    this.setState({show: false});
    this.props.deleteDocument(docID)
  }

  handleDocumentTitle = (event) => {

    this.setState({eventDocumentTitle: event.target.value})
    console.log('onType', this.state.eventDocumentTitle)
  }

  handleAlarmCodes = (event) => {

    this.setState({eventAlarmCodes: event.target.value})
    console.log('onType', this.state.eventAlarmCodes)
  }


/*
  onSubmitHandle(event) {
  event.preventDefault();
  this.setState({

  });
  console.log('tsting')
  event.target.item.value = '';
}*/


  render(){
    console.log(`marketlistedit props ${this.props}`)
    console.log('Props', this.props)


    if(this.props.isLoading && this.props.incorrectPasswordUser){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>You entered an incorrect username or password</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsstyle="primary">Try Again</Button>
          </LinkContainer>
        </div>
      )
    }else if (this.props.isLoading  || this.props.sessionIsLoading) {
        return(
          <div className="viewPage">
            <Jumbotron>
              <h1>TurbineRunner is Loading</h1>
            </Jumbotron>
          </div>
        )
      }

    /*if(!this.props.isAuthenticated){
      return(
        <div className="viewPage">
          <Jumbotron>
            <h1>you are not logged in go back</h1>
          </Jumbotron>
          <LinkContainer to="/login">
            <Button  bsStyle="primary">login</Button>
          </LinkContainer>
        </div>
      )
    }*/
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredDocuments = this.props.documents.filter(
      (doc) => {

        if(doc.alarmCode !== undefined){
          return doc.title.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1 || doc.alarmCode.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1
        }

      }
    )


    return(

      <div className="viewPage Hero-banner">
        <Jumbotron>
          <h1>{this.props.userOrganization}</h1>
          <h4>Welcome {this.props.userFirstName}</h4>
        </Jumbotron>

        <Container>
        <div>
        <br/>
        <br/>
        </div>
          <Row className="Testing2"  >
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="formControlsTextarea2">
                  <FormLabel>Search</FormLabel>

                  <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Document Title or Fault Code" />

              </FormGroup>
            </Col>
          </Row>
          <Row className="Testing2"  >
            <Col xs={1} sm={1} md={1} lg={1}>
              <h6>#</h6>
            </Col>
            <Col xs={6} sm={4} md={4} lg={4}>
              <h6>Document Title</h6>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <h6>Related Fault Codes</h6>
            </Col>
            <Col className="d-none d-sm-block" sm={2} md={2} lg={2}>
              <h6>Turbine Model</h6>
            </Col>
            <Col xs={3} sm={3} md={3} lg={3}>
              <h6>Action</h6>
            </Col>
          </Row>

            {filteredDocuments.filter(doc => doc._id !== 0 && doc._id !== "template").map((doc, i) => (

              <Row className="Testing" key={i}>
                <Col xs={1} sm={1} md={1} lg={1}>
                  <p>{i + 1}</p>
                </Col>
                <Col xs={6} sm={4} md={4} lg={4}>
                  <p>{doc.title}</p>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2}>
                  <p>{doc.alarmCode}</p>
                </Col>
                <Col className="d-none d-sm-block" sm={2} md={2} lg={2}>
                  <p>{doc.turbineModel}</p>
                </Col>
                <Col xs={3} sm={3} md={3} lg={3}>
                  <LinkContainer to="/viewdocument">
                    <Button onClick={() => this.props.viewPage(doc.firstPageID)} bsstyle="success">Select</Button>
                  </LinkContainer>&nbsp;&nbsp;
                </Col>

                {/* <Modal show={this.state.show} onHide={this.handleClose}>
                 <Modal.Header closeButton>
                   <Modal.Title>Delete Document</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>Warning!!! Are you sure you want to delete the document "{this.state.selectedDocument.title}"?</Modal.Body>
                 <Modal.Footer>
                   <Button variant="secondary" onClick={this.handleClose}>
                     Close
                   </Button>
                   <Button variant="danger" onClick={() => this.handleCloseAndDelete(this.state.selectedDocument._id)}>
                     Delete
                   </Button>
                 </Modal.Footer>
               </Modal> */}

              </Row>
              ))}

            </Container>

      </div>
    )
  }
}
export default ViewDocumentList;
