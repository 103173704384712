//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {Jumbotron, Button, Table, ButtonToolbar, FormGroup, FormLabel, FormControl, Container, Row, Col, Modal, Tab, Tabs } from 'react-bootstrap';
//below is needed to use react-bootstrap with react-router
import { LinkContainer } from 'react-router-bootstrap';


class UserList extends Component{
  state = {
    query: '',
    eventMarketName: '',
    eventFirstName: '',
    eventLastName: '',
    eventEMail: '',
    eventUserType: '',
    eventUserOrganization: '',

    show: false,
    selectedUser: '',
  }

  updateQuery = (query) => {
    if(query !== "" || query !== undefined){
      this.setState({
        query: query.target.value.substr(0,40)
      })

    }
  }

  handleShow = (user) => {
    this.setState(
      {
        show: true,
        selectedUser: user,
      });
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleCloseAndDelete = (userID) => {

    this.setState({show: false});
    this.props.deleteUser(userID)
  }

  handleFirstNameChange = (event) => {

    this.setState({eventFirstName: event.target.value})
    console.log('onType', this.state.eventFirstName)
  }

  handleLastNameChange = (event) => {

    this.setState({eventLastName: event.target.value})
    console.log('onType', this.state.eventLastName)
  }

  handleEMailChange = (event) => {

    this.setState({eventEMail: event.target.value})
    console.log('onType', this.state.eventEMail)
  }

  handlePasswordChange = (event) => {

    this.setState({eventPassword: event.target.value})
    console.log('onType', this.state.eventPassword)
  }

  handleUserTypeChange = (event) => {

    this.setState({eventUserType: event.target.value})
    console.log('onType', this.state.eventUserType)
  }

  handleUserOrganizationChange = (event) => {

    this.setState({eventUserOrganization: event.target.value})
    console.log('onType', this.state.eventUserOrganization)
  }

/*
  onSubmitHandle(event) {
  event.preventDefault();
  this.setState({

  });
  console.log('tsting')
  event.target.item.value = '';
}*/


  render(){
    console.log('Props', this.props)

    //this is for the initial list when the page loads
    let filteredUsers = this.props.users.filter(
      (user) => {

        return user.userFirstName.toLowerCase().indexOf(this.state.query.toLowerCase()) !== -1
      }
    )


    return(
      <div className="viewPage">

        <Container>
        <h4>New User / Edit User</h4>
        <div>
        <form >
          <FormGroup  controlId="formControlsTextarea">

              <FormLabel>First Name</FormLabel>
              <FormControl onChange={this.handleFirstNameChange}  placeholder="Enter First Name" style={{ height: 30 }}/>
              <FormLabel>Last Name</FormLabel>
              <FormControl onChange={this.handleLastNameChange}  placeholder="Enter Last Name" style={{ height: 30 }}/>
              <FormLabel>E-Mail</FormLabel>
              <FormControl onChange={this.handleEMailChange}  placeholder="Enter E-Mail" style={{ height: 30 }}/>
              <FormLabel>Password</FormLabel>
              <FormControl onChange={this.handlePasswordChange}  placeholder="Enter Password" style={{ height: 30 }}/>
              <FormLabel>User Type</FormLabel>
              <FormControl onChange={this.handleUserTypeChange}  placeholder="Enter User Type" style={{ height: 30 }}/>
              <FormLabel>User Organization</FormLabel>
              <FormControl onChange={this.handleUserOrganizationChange}  placeholder="Enter User Organization" style={{ height: 30 }}/>

          </FormGroup>
          <Button onClick={() => this.props.newUser(this.state.eventFirstName, this.state.eventLastName, this.state.eventEMail, this.state.eventPassword, this.state.eventUserType, this.state.eventUserOrganization)} bsstyle="success">Submit New User</Button>
        </form>
        <br/>
        <br/>
        </div>
          <Row className="Testing2"  >
            <Col xs={12} sm={12} md={12} lg={12}>
              <FormGroup controlId="formControlsTextarea2">
                  <FormLabel>Search</FormLabel>

                  <FormControl type="text" onChange={this.updateQuery} value={this.state.query} placeholder="Search Contact Name or Contact ID" />

              </FormGroup>
            </Col>
          </Row>
          <Row className="Testing2"  >
            <Col xs={1} sm={1} md={1} lg={1}>
              <h4>#</h4>
            </Col>

            <Col xshidden="true" sm={3} md={3} lg={2}>
              <h5>Password</h5>
            </Col>
            <Col xs={8} sm={5} md={5} lg={2}>
              <h5>First Name</h5>
            </Col>
            <Col xs={8} sm={5} md={5} lg={1}>
              <h5>Last Name</h5>
            </Col>
            <Col xs={8} sm={5} md={5} lg={3}>
              <h5>E-Mail</h5>
            </Col>
            <Col xs={8} sm={5} md={5} lg={1}>
              <h5>Role</h5>
            </Col>
            <Col xs={3} sm={3} md={3} lg={2}>
              <h5>Action</h5>
            </Col>
          </Row>

            {filteredUsers.filter(user => user.userID !== 0 && user.userID !== "template").map((user, i) => (

              <Row className="Testing" key={i}>
                <Col xs={1} sm={1} md={1} lg={1}>
                  <p>{i + 1}</p>
                </Col>

                <Col xshidden="true" sm={3} md={3} lg={2}>

                  <p>{user.userPassword !== undefined ? user.userPassword.substring(user.userPassword.length - 8, user.userPassword.length) : "No password"}</p>
                </Col>
                <Col xs={8} sm={5} md={5} lg={2}>
                  <p>{user.userFirstName}</p>
                </Col>
                <Col xs={8} sm={5} md={5} lg={1}>
                  <p>{user.userLastName}</p>
                </Col>
                <Col xs={8} sm={5} md={5} lg={3}>
                  <p>{user.userEMail}</p>
                </Col>
                <Col xs={8} sm={5} md={5} lg={1}>
                  <p>{user.userRole}</p>
                </Col>
                <Col xs={2} sm={3} md={3} lg={2}>
                  <LinkContainer to="/edituseradmin">
                    <Button onClick={() => this.props.viewUser(user._id)}>Edit</Button>
                  </LinkContainer>
                    {/*<Button onClick={() => this.props.deleteUser(user._id)} variant="danger">Delete</Button> */}
                    <Button variant="danger" onClick={() => this.handleShow(user)}>
                      Delete
                    </Button>
                </Col>

                <Modal show={this.state.show} onHide={this.handleClose}>
                 <Modal.Header closeButton>
                   <Modal.Title>Delete User</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>Warning!!! Are you sure you want to delete the user "{this.state.selectedUser.userFirstName} {this.state.selectedUser.userLastName}"?</Modal.Body>
                 <Modal.Footer>
                   <Button variant="secondary" onClick={this.handleClose}>
                     Close
                   </Button>
                   <Button variant="danger" onClick={() => this.handleCloseAndDelete(this.state.selectedUser._id)}>
                     Delete
                   </Button>
                 </Modal.Footer>
                </Modal>

              </Row>

              ))}

            </Container>
            <br/>
            <br/>

      </div>
    )
  }
}
export default UserList;
