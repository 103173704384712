//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React

import React, {Component} from 'react';
import {fakeAuth} from '../utils/Authenticate_t.js'
import {Nav, NavDropdown, Navbar, Button, ButtonToolbar} from 'react-bootstrap';
import { LinkContainer} from 'react-router-bootstrap';
import { Link } from 'react-router-dom'


class Navigation extends Component{


  render(){
    console.log('Props', this.props)


    if(this.props.isAuthenticated === "true" && this.props.userType.toLowerCase() === "administrator"){
    return(

      <div>

        <Navbar bg="dark" variant="dark" expand="lg">

          <LinkContainer to="/viewdocumentlist">
            <Navbar.Brand>TurbineRunner</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <LinkContainer to="/viewdocumentlist">
                <Nav.Link>Documents</Nav.Link>
              </LinkContainer>
              <Nav>
              <NavDropdown title="Edit" id="basic-nav-dropdown">
                <LinkContainer to="/editdocumentlist">
                  <NavDropdown.Item>Document Editor</NavDropdown.Item>
                </LinkContainer>
                {/*<LinkContainer to="/pagelist">
                  <NavDropdown.Item>Admin Page List</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/userlistedit">
                  <NavDropdown.Item>Admin User List</NavDropdown.Item>
                </LinkContainer>*/}
              </NavDropdown>

              </Nav>
              <Nav>
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                <LinkContainer to="/documentlist">
                  <NavDropdown.Item>Admin Document List</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/pagelist">
                  <NavDropdown.Item>Admin Page List</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/userlistedit">
                  <NavDropdown.Item>Admin User List</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              </Nav>
            </Nav>
            <Nav className="ml-auto">
              <Nav.Link onClick={this.props.logOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }else if(this.props.isAuthenticated === "true"){
  return(

    <div>

      <Navbar bg="dark" variant="dark" expand="lg">

        <LinkContainer to="/viewdocumentlist">
          <Navbar.Brand>TurbineRunner</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <LinkContainer to="/viewdocumentlist">
              <Nav.Link>Documents</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ml-auto">
            <Nav.Link onClick={this.props.logOut}>Log Out</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}else{

    return(

      <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/documentlist">TurbineRunner</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">

          </Nav>
        </Navbar.Collapse>
      </Navbar>


      </div>


    )
  }
  }
}
export default Navigation;
