//LinkedIn Learning: Learning Full-Stack Javascript Development: MongoDB, Node, and React
//ES6

// import {getFromStorage, setInStorage} from './storage.js'
// import {fakeAuth} from './Authenticate_t.js'
import * as Const from './Constants'

/*********************** Comments ***************************/

export const getComments = (jwt) =>
  fetch(`${Const.API}/comment`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization": jwt
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const getCommentWithId = (id) =>
  fetch(`${Const.API}/comment/${id}`)
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const newComment = (comment) =>
  fetch(`${Const.API}/comment`, {
    method: "POST",
    body: JSON.stringify(comment),
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("Something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const updateComment = (id, comment) =>
  fetch(`${Const.API}/comment/${id}`, {
    method: "PUT",
    body: JSON.stringify(comment),
    headers: {
      "Content-Type": "application/json",
    }
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response.json();
    }
  )

export const deleteComment = (id) =>
  fetch(`${Const.API}/comment/${id}`,
  {
    method: "DELETE",
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )

export const deleteManyComments = (id) =>
  fetch(`${Const.API}/comments/${id}`,
  {
    method: "DELETE",
  })
  .then(
    function(response){
      if(response.status !== 200){
        console.log("something went wrong" + response.status);
        return;
      }
      return response;
    }
  )



  //Learning Resources
  //about fetch
  //https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
  //https://alligator.io/js/fetch-api/
  //https://developers.google.com/web/updates/2015/03/introduction-to-fetch
