import React, {Component} from 'react';
import { Container, Jumbotron, Button, Table, ButtonToolbar, DropdownButton, MenuItem, Image, FormGroup, FormControl, Form, Col, Checkbox } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom';
import * as ContactsAPI from '../utils/ContactsAPI'
//import {getFromStorage, setInStorage} from '../utils/storage.js'



class Login extends Component{

  state = {

    eventEMail: "",
    eventPassword: "",
    token2: '',
    redirectToReferrer: false,

  }

  componentDidMount(){

    this.setState({eventEMail: ''})
    this.setState({eventPassword: ''})
    //this.props.updateLoggedIn()
  }


  handleChangeEMail = (event) => {

    this.setState({eventEMail: event.target.value


    })

    console.log('eventEMail', this.state.eventEMail)
  }

  handleChangePassword = (event) => {

    this.setState({eventPassword: event.target.value


    })

    console.log('eventPassword', this.state.eventPassword)
  }


  printValues = (event) => {

    console.log('values',
    this.state.eventEMail,
    this.state.password,

  )
  }

  render(){
    console.log('Props', this.props)

    return(

      <div className="viewPage Hero-banner">

        <Jumbotron>
          <h1>TurbineRunner</h1>
        </Jumbotron>


          <h2>Sign into your account</h2>
          <br />

          {console.log("hello world")}

        <div className="centerLogin">
        <Form horizontal="true">


          <Form.Group controlId="formHorizontalEmail">
            <Col>
              Email
            </Col>
            <Col>
              <FormControl type="email" onChange={this.handleChangeEMail} placeholder="E-Mail" />
            </Col>
          </Form.Group>


          <FormGroup controlId="formHorizontalPassword">
            <Col>
              Password
            </Col>
            <Col>
              <FormControl type="password" onChange={this.handleChangePassword} placeholder="Enter Password" />
            </Col>
          </FormGroup>

          <LinkContainer to="/viewdocumentlist">
            <Button onClick={() => this.props.signInUser(this.state.eventEMail, this.state.eventPassword)} bsstyle="warning">Sign in</Button>
            {/* <Button onClick={() => this.props.logInTesting(this.state.eventEMail, this.state.eventPassword, this.state.redirectToReferrer)} bsStyle="warning">Sign in</Button> */}

          </LinkContainer>

          <p>Forgot your password?</p>

        </Form>
        </div>


      </div>
    )
  }
}
export default Login;
